import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTheme } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { BiShowAlt } from "react-icons/bi";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import { axiosClient } from "../../helper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className="flex-shrink-0">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

const InvoiceTable = (props) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const buyerId = localStorage.getItem("userId");

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, invoiceData?.length - page * rowsPerPage);

  useEffect(() => {
    axiosClient()
      .get(`/getInvoicesByBuyerId/${buyerId}`)
      .then((res) => {
        setLoader(false);
        setInvoiceData(res.data.data);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10}>
          <h1 className="page-heading">Invoices</h1>
          {loader ? (
            <div className="loaders">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="my_orders">
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order #</TableCell>
                    <TableCell>Bill To</TableCell>
                    <TableCell style={{ maxWidth: "300px" }}>
                      Item Description
                    </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.orderId.refId}</TableCell>
                      <TableCell>{data.orderId?.customerName}</TableCell>
                      <TableCell style={{ maxWidth: "300px" }}>
                        {data.orderId?.orderItems?.map((item, index) => (
                          <>
                            <span key={index}>
                              <strong>{item.quantity} x </strong>
                              {item.productName}
                            </span>
                            <br />
                          </>
                        ))}
                      </TableCell>

                      <TableCell>{data.status}</TableCell>
                      <TableCell>{data.orderId?.totalPrice}</TableCell>
                      <TableCell>
                        <Link
                          to={`/invoice-details/${data._id}`}
                          style={{
                            paddingLeft: "18px",
                          }}
                        >
                          <BiShowAlt
                            style={{
                              fontSize: "20px",
                              color: "#494949",
                            }}
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                  {invoiceData?.length <= 0 ? (
                    <h5 className="no_orders">No invoices found</h5>
                  ) : (
                    ""
                  )}
                  {emptyRows >= 10 && (
                    <TableRow style={{ height: 70 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter className="main_rows">
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={invoiceData?.length > 0 ? invoiceData.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default InvoiceTable;
