import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Input from "@material-ui/core/Input";
import SpadaLoader from "../../assets/loader/spadal.mp4";

import CircularProgress from "@material-ui/core/CircularProgress";
import { axiosClient } from "../../helper";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import { withRouter, useHistory } from "react-router-dom";
import ReferencePopup from "../../components/referencePopup/referencePopup";

function ReturnRequest(props) {
  // const [singleOrder, setSingleOrder] = useState();
  const [reason, setReason] = useState("");
  const [returnOrderId, setReturnOrderId] = useState("");
  const [returnId, setReturnId] = useState("");
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [referenceId, setReferenceId] = useState("");
  const [loader, setLoader] = useState(false);
  const [spadaloader, setSpadaLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [returnProduct, setReturnProduct] = useState("");

  let history = useHistory();
  useEffect(() => {
    setSpadaLoader(true);
    let id = props.match.params.id;
    let productId = props.match.params.productId;
    console.log("productId: ", productId);
    setReturnOrderId(id);
    getOrderById(id, productId);
  }, []);

  const handleClose = () => {
    setShow(false);
    history.push("/myorders");
  };
  const handleShow = () => setShow(true);

  const getOrderById = (id, productId) => {
    axiosClient()
      .get(`/getOrderById/${id}`)
      .then((response) => {
        setSpadaLoader(false);
        if (response.status === 200) {
          // setSingleOrder(response.data.order);
          setReferenceId(response.data.order.refId);
          let productToReturn = response.data.order.orderItems.filter(
            (p) => p.productId === productId
          );
          setReturnProduct(productToReturn[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCreateRequest = () => {
    let returnData = {
      orderId: returnOrderId,
      // orderItems: selectedOrderItems,
      orderItems: returnProduct,
      buyerId: localStorage.getItem("userId"),
      reason: reason,
      refId: referenceId,
    };
    if (!localStorage.getItem("userId") || !selectedOrderItems || !reason) {
      toast.error("All fields are required");
    } else {
      setLoader(true);
      axiosClient()
        .post(`/createReturnOrderRequest`, returnData)
        .then((response) => {
          console.log("create request response: ", response);
          setLoader(false);
          handleShow();
          setReturnId(response.data.order._id);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response?.data.message);
          // toast.error("Request not created, please try again");
        });
    }
  };

  // const onChangeOrderItems = (e) => {
  //   setSelectedOrderItems(e.target.value);
  // };

  const onChangeReason = (e) => {
    setReason(e.target.value);
  };
  return (
    <Container>
      {spadaloader ? (
        <div className="post_loader">
          <img src={SpadaLoader} className="spadaloader-image" />
        </div>
      ) : (
        ""
      )}
      <ToastContainer autoClose={3000} />
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10} className="return_request">
          <h1 className="page-heading">New Return Request</h1>
          {/* <h1 className="return_request_heading"></h1> */}
          <div className="create-request">
            <Form.Group as={Row}>
              <Col sm={12} md={12} lg={12}>
                <Form.Label>Product to return</Form.Label>
              </Col>
              <Col sm={12} md={5} lg={5}>
                <Form.Control
                  type="text"
                  name="product"
                  readOnly
                  defaultValue={returnProduct?.productName}
                  // onChange={this.handleChangeInput}
                />
                {/* <FormControl>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    placeholder="Choose Products"
                    multiple
                    value={selectedOrderItems}
                    onChange={onChangeOrderItems}
                    input={<Input />}
                    disableUnderline={true}
                    style={{ background: "#fff", borderRadius: "6px" }}
                  >
                    {singleOrder?.orderItems.map(
                      (orderitem) => (
                        console.log("orderitem: ", orderitem),
                        (
                          <MenuItem key={orderitem._id} value={orderitem}>
                            {orderitem.productName}
                          </MenuItem>
                        )
                      )
                    )}
                  </Select>
                </FormControl> */}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={12} md={12} lg={12}>
                <Form.Label>Reasons to return</Form.Label>
              </Col>
              <Col sm={12} md={5} lg={5}>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="write the reason to return order"
                  rows={3}
                  value={reason}
                  onChange={onChangeReason}
                />
              </Col>
            </Form.Group>

            <Button
              className="return_button"
              onClick={handleCreateRequest}
              style={{ width: "136px", float: "inherit" }}
            >
              Create Request
            </Button>
            <ReferencePopup
              handleClose={handleClose}
              show={show}
              referenceId={returnId}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(ReturnRequest);
