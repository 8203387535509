import React, { Component } from "react";
import "./ShopFilterSection.css";
import { axiosClient } from "../../helper";

class ShopFilterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategory: [],
      featuredbrands: [],
      sellers: [
        "Spada Traders.com",
        "Mosiso",
        "Tech Vendor",
        "Fintie",
        "Cable Matters",
        "Superer",
        "CableCreation",
        "Talent Image",
        "UGREEN GROUP LIMITED",
        "PWR+",
      ],
      checkedBrand: [],
      checkedname: [],

      location: [
        { _id: 1, name: "Lahore" },
        { _id: 2, name: "Karachi" },
        { _id: 3, name: "Islamabad" },
        { _id: 4, name: "Multan" },
        { _id: 5, name: "Peshawar" },
        { _id: 6, name: "Sargodha" },
        { _id: 7, name: "Sukkur" },
        { _id: 8, name: "Quetta" },
        { _id: 9, name: "Muzaffarabad" },
        { _id: 10, name: "Kashmir" },
      ],
    };
  }
  componentDidMount = () => {
    axiosClient()
      .get("/getAllBrands")
      .then((res) => {
        this.setState({ featuredbrands: res.data.brands });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  componentWillMount() {
    this.props.getSubcategory();
  }

  render() {
    return (
      <div className="filter-main-div">
        <h3 className="show-refine">Show results for</h3>
        <div style={{ marginLeft: "14px" }}>
          {this.props.subCategory !== undefined ? (
            <div className="list-div">
              <h4 className="list-heading1">Subcategories</h4>
              {this.props.subCategory?.map((subcategory, i) => (
                <div
                  key={i}
                  className="d-flex align-items-center"
                  style={{ marginLeft: "2px" }}
                >
                  <input
                    onChange={(e) => {
                      this.props.handleToggleSubCategories(
                        subcategory._id,
                        subcategory.subCategoryName,
                        e
                      );
                    }}
                    type="checkbox"
                    value={subcategory._id}
                    checked={
                      this.props.checkedSubCategories?.indexOf(
                        subcategory._id
                      ) === -1
                        ? false
                        : true
                    }
                  />
                  <label
                    className="list-item list-item-margin"
                    style={{ marginLeft: "4px" }}
                  >
                    {subcategory.subCategoryName}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          <hr />
          <div className="list-div">
            {this.state.featuredbrands?.length > 0 && (
              <h4 className="list-heading">Brands</h4>
            )}
            {this.state.featuredbrands?.map((featuredbrand, i) => (
              <div
                key={i}
                className="d-flex align-items-center"
                style={{ marginLeft: "2px" }}
              >
                <input
                  onChange={(e) => {
                    this.props.handleToggleBrand(
                      featuredbrand._id,
                      featuredbrand.name,
                      e
                    );
                  }}
                  type="checkbox"
                  value={featuredbrand.name}
                  checked={
                    this.props.checkedBrand.indexOf(featuredbrand._id) === -1
                      ? false
                      : true
                  }
                />
                <label
                  className="list-item list-item-margin"
                  style={{ marginLeft: "4px" }}
                >
                  {featuredbrand.name}
                </label>
              </div>
            ))}
          </div>

          {/* <div className="list-div">
            <h4 className="list-heading">Location</h4>
            {this.state.location.map((locat) => (
              <div style={{ marginLeft: "2px" }}>
                <input
                  onChange={(e) => {
                    this.props.handleToggleLocation(locat._id, locat.name, e);
                  }}
                  type="checkbox"
                  value={locat.name}
                  checked={
                    this.props.checkedLocation.indexOf(locat._id) === -1
                      ? false
                      : true
                  }
                />
                <label
                  className="list-item list-item-margin"
                  style={{ marginLeft: "4px" }}
                >
                  {locat.name}
                </label>
              </div>
            ))}
          </div> */}

          <div className="list-div">
            <h4 className="list-heading" style={{ marginBottom: "8px" }}>
              Price
            </h4>
            <ul style={{ marginRight: "10px" }}>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(0, 0);
                }}
                className="list-item"
              >
                All Prices
              </li>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(0, 25);
                }}
                className="list-item"
              >
                Under PKR 25
              </li>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(25, 50);
                }}
                className="list-item"
              >
                PKR 25 to 50
              </li>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(50, 100);
                }}
                className="list-item"
              >
                PKR 50 to 100
              </li>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(100, 200);
                }}
                className="list-item"
              >
                PKR 100 to 200
              </li>
              <li
                onClick={() => {
                  this.props.setMinMaxPrice(200, 9999999999999);
                }}
                className="list-item"
              >
                PKR 200 and Above
              </li>
            </ul>
          </div>
          {/* <h3 className="show-refine">Refine by</h3>
          <div className="list-div">
            <h4 className="list-heading">Spada Traders Certified</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Auto Replenishment
              </label>
            </div>
            <div style={{ marginTop: "-2px", marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Works with Alexa
              </label>
            </div>
          </div> */}
          {/* <div className="list-div">
            <h4 className="list-heading">From Our Brands</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Our Brands
              </label>
            </div>
          </div> */}

          {/* <div className="list-div">
            <h4 className="list-heading">Location Options</h4>
            <div style={{ marginTop: "-2px", marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Pakistan
              </label>
            </div>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                China
              </label>
            </div>
          </div>
          <div className="list-div">
            <h4 className="list-heading">Shipping Options</h4>
            <div style={{ marginTop: "-2px", marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Free Shipping
              </label>
            </div>
          </div>
          <div className="list-div">
            <h4 className="list-heading">Packaging Option</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Frustration-Free Packaging
              </label>
            </div>
          </div> */}
          {/* <div className="list-div">
            <h4 className="list-heading" style={{ marginBottom: "8px" }}>
              Avg. Customer Review
            </h4>
            <ul style={{ marginRight: "10px" }}>
              <li className="list-item">
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiOutlineStar className="star" /> & up
              </li>
              <li className="list-item">
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" /> & up
              </li>
              <li className="list-item">
                <AiFillStar className="star fill-star" />
                <AiFillStar className="star fill-star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" /> & up
              </li>
              <li className="list-item">
                <AiFillStar className="star fill-star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" />
                <AiOutlineStar className="star" /> & up
              </li>
            </ul>
          </div> */}
          {/* <div className="list-div">
            <h4 className="list-heading" style={{ marginBottom: "8px" }}>
              New & Upcoming
            </h4>
            <ul style={{ marginRight: "10px" }}>
              <li className="list-item">New Arrivals</li>
              <li className="list-item">Coming Soon</li>
            </ul>
          </div>
          <div className="list-div">
            <h4 className="list-heading">Certifications</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Energy Star
              </label>
            </div>
          </div>
          <div className="list-div">
            <h4 className="list-heading">Spada Traders Global Store</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Spada Traders Global Store
              </label>
            </div>
          </div>
          <div className="list-div">
            <h4 className="list-heading" style={{ marginBottom: "8px" }}>
              Condition
            </h4>
            <ul style={{ marginRight: "10px" }}>
              <li className="list-item">New</li>
              <li className="list-item">Used</li>
              <li className="list-item">Renewed</li>
            </ul>
          </div> */}

          {/* <div className="list-div">
            <h4 className="list-heading">Spada Traders Global Store</h4>
            {this.state.sellers.map((seller) => (
              <div style={{ marginLeft: "2px" }}>
                <input type="checkbox" value="" />
                <label
                  className="list-item list-item-margin"
                  style={{ marginLeft: "4px" }}
                >
                  {seller}
                </label>
              </div>
            ))}
          </div>
          <div className="list-div">
            <h4 className="list-heading">Availability</h4>
            <div style={{ marginLeft: "2px" }}>
              <input type="checkbox" value="" />
              <label
                className="list-item list-item-margin"
                style={{ marginLeft: "4px" }}
              >
                Include Out of Stock
              </label>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default ShopFilterSection;
