import React from "react";
import { Grid, Typography, Link, TableCell } from "@material-ui/core";
// import { useRouter } from "next/router";
import BlogImage from "../../assets/blog-card-img.jpg";
import { BlogsData } from "../../data/data";
import moment from "moment";
import "./index.css";
export default function blogsCard({}) {
  console.log(blogsCard, "in comp");
  return (
    <>
      <a href="/blogdetails">
        <Grid className="blogs-card-main">
          <Grid className="blogs-card">
            <Grid className="blogs-img-div">
              <img
                //   src={blogsCard.picture}
                src={BlogImage}
                className="blogs-image-div"
                alt="blogs image"
              />
            </Grid>
            <Grid className="blogs-text-main-div">
              <Grid className="blogs-text-div">
                <Typography className="inspiration-text">
                  INSPIRATION
                  {/* {blogsCard.heading} */}
                </Typography>
                <Typography className="blogs-date-text">/</Typography>
                <Typography className="blogs-date-text">
                  {/* {moment(blogsCard.time).format("MMM Do YY")}; */}
                  12 january
                </Typography>
              </Grid>

              <Grid>
                <Typography className="blogs-trend-text">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: blogsCard.sureDiscription,
                    }}
                  /> */}
                  {/* {blogsCard.heading} */}
                  heading
                  {/* {blogsCard.discription} */}
                </Typography>
              </Grid>

              <Grid>
                <Typography className="blogs-text-3">
                  {/* {blogsCard.sureDiscription} */}
                  description
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </a>
    </>
  );
}
