import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import { useStateValue } from "../../StateProvider";
import "./Shop.css";
import ShopFilterSection from "../../components/ShopFilterSection/ShopFilterSection";
import ShopProductCard from "../../components/ShopProductCard/ShopProductCard";
import { axiosClient } from "../../helper";
import PaginationControlled from "./pagination";
let newnameBrand = [];
let newnameLocation = [];
let newnameSubCategories = [];

const Shop = () => {
  const params = useParams();
  let history = useHistory();
  const [{ choosedLocation }] = useStateValue();
  const [categories, setCategories] = useState([
    {
      title: "COMPUTER ACCESSORIES",
      image:
        "https://images-na.ssl-images-amazon.com/images/G/01/amazonglobal/images/email/asins/DURM-244337D24F250BSI._V533746475_.jpg",
    },
    {
      title: "COMPUTER COMPONENTS",
      image:
        "https://images-na.ssl-images-amazon.com/images/G/01/amazonglobal/images/email/asins/DURM-2447E617EC5C463L._V533746472_.jpg",
    },
    {
      title: "COMPUTERS & TABLETS",
      image:
        "https://images-na.ssl-images-amazon.com/images/G/01/amazonglobal/images/email/asins/DURM-244373AC9B3C4612._V533746474_.jpg",
    },
    {
      title: "NETWORKING PRODUCTS",
      image:
        "https://images-na.ssl-images-amazon.com/images/G/01/amazonglobal/images/email/asins/DURM-24480C97FFA81710._V533746472_.jpg",
    },
  ]);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);

  const [categoryId, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [filters, setFilters] = useState({ featuredbrands: [] });
  const [isAll, setIsAll] = useState(false);
  const [filterBrands, setFilterBrands] = useState([]);
  const [sellerId, setSellerId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subProducts, setSubProducts] = useState([]);
  const [brand, setBrand] = useState([]);
  const [singleCategory, setSingleCategory] = useState("");
  const [activeItemIndex, setActiveItemIndex] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [brandNames, setBrandNames] = useState([]);
  const [locationNames, setLocationNames] = useState([]);
  const [subCategoriesName, setSubCategoriesName] = useState([]);

  const [checkedBrand, setCheckedBrand] = useState([]);
  const [checkednameBrand, setCheckednameBrand] = useState([]);

  const [checkedLocation, setCheckedLocation] = useState([]);
  const [checkednameLocation, setCheckednameLocation] = useState([]);

  const [checkedSubCategories, setCheckedSubCategories] = useState([]);
  const [checkednameSubCategories, setCheckednameSubCategories] = useState([]);

  const [filterData, setFilterData] = useState({
    brand: [],
    categoryId: [],
    subCategoryId: [],
    locationByCity: [],
    minPrice: "",
    maxPrice: "",
  });
  const [productLoader, setProductLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [productsLength, setProductsLength] = useState(0);

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    let id = params.id;
    setCategoryId(id);
    if (id === "all-products") {
      getAllProducts();
      setIsNew(true);
    } else {
      getProductsByCategoryId(id);
      getSubcategoryByCategoryId(id);
    }
    getSingleCategory(id);
  }, [params.id, choosedLocation, pageNumber, params.location]);

  useEffect(() => {
    history.push(`/shop/${params.id}/${pageNumber}/${choosedLocation}`);
  }, [choosedLocation]);

  const setMinMaxPrice = (minPrice, maxPrice) => {
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    handleFilters(
      brandNames,
      subCategoriesName,
      // locationNames,
      choosedLocation,
      minPrice,
      maxPrice
    );
  };

  const handleToggleBrand = (value, name, e) => {
    const currentIndexBrand = checkedBrand.indexOf(value);
    const newCheckedBrand = [...checkedBrand];

    if (currentIndexBrand === -1) {
      newCheckedBrand.push(value);
      newnameBrand.push(e.target.value);
    } else {
      newCheckedBrand.splice(currentIndexBrand, 1);
      newnameBrand.splice(currentIndexBrand, 1);
    }
    setCheckedBrand(newCheckedBrand);
    setBrandNames(newnameBrand);
    handleFilters(
      newnameBrand,
      subCategoriesName,
      // locationNames,
      choosedLocation,
      minPrice,
      maxPrice
    );
  };

  const handleToggleLocation = (value, name, e) => {
    const currentIndexLocation = checkedLocation.indexOf(value);
    const newCheckedLocation = [...checkedLocation];

    if (currentIndexLocation === -1) {
      newCheckedLocation.push(value);
      newnameLocation.push(e.target.value);
    } else {
      newCheckedLocation.splice(currentIndexLocation, 1);
      newnameLocation.splice(currentIndexLocation, 1);
    }
    setCheckedLocation(newCheckedLocation);
    setLocationNames(newnameLocation);
    handleFilters(
      brandNames,
      subCategoriesName,
      newnameLocation,
      minPrice,
      maxPrice
    );
  };

  const handleToggleSubCategories = (value, name, e) => {
    const currentIndexSubCategories = checkedSubCategories.indexOf(value);
    const newCheckedSubCategories = [...checkedSubCategories];

    if (currentIndexSubCategories === -1) {
      newCheckedSubCategories.push(value);
      newnameSubCategories.push(e.target.value);
    } else {
      newCheckedSubCategories.splice(currentIndexSubCategories, 1);
      newnameSubCategories.splice(currentIndexSubCategories, 1);
    }
    setCheckedSubCategories(newCheckedSubCategories);
    // this.setState({ checkednameSubCategories: newnameSubCategories });
    setSubCategoriesName(newnameSubCategories);
    handleFilters(
      brandNames,
      newnameSubCategories,
      // locationNames,
      choosedLocation,
      minPrice,
      maxPrice
    );
  };

  const getAllProducts = () => {
    axiosClient()
      .get("/getFeaturedProducts")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.products.length <= 0) {
            setProducts([]);
          } else {
            setProducts(response.data.products);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSingleCategory = (id) => {
    axiosClient()
      .get(`/getCategoryById/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSingleCategory(response.data.category);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductsByCategoryId = (id) => {
    axiosClient()
      .get(
        `/admin/getProductsByCategoryId/${id}/${pageNumber}/${choosedLocation}`
      )
      .then((response) => {
        setProductLoader(false);
        if (response.status === 200) {
          if (response.data.products.length <= 0) {
            setProducts([]);
          } else {
            setProducts(response.data.products);
            setProductsLength(response.data.length);
          }
        }
      })
      .catch((error) => {
        setProductLoader(false);
        console.log(error);
      });
  };

  const getSubcategoryByCategoryId = (id) => {
    axiosClient()
      .get(`/getSubCategoriesByCategoryId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSubCategory(response.data.subCategory);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductsBySubcategoryId = (id) => {
    setSubCategoryId(id);
  };

  const handleFilters = (
    brand,
    subCategoryId,
    locationByCity,
    minPrice,
    maxPrice
  ) => {
    axiosClient()
      .post(`/getProductsByFilter`, {
        brand: brand,
        categoryId: [params.id],
        subCategoryId: subCategoryId,
        locationByCity: locationByCity,
        minPrice: Number(minPrice),
        maxPrice: Number(maxPrice),
      })
      .then((response) => {
        if (response.status === 200) {
          setProducts(response.data.productsByBrand);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubCategoryId = (subCatId) => {
    setActiveItemIndex(subCatId);
    axiosClient()
      .get(`/admin/getProductsBySubCategoryId/${subCatId}`)
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // deleteWishlistProduct = async (productdata) => {
  //   try {
  //     await axiosClient().delete(
  //       `/removeProductFromWishList/${productdata._id}`
  //     );
  //     alert("removed from wishlist");
  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 404) {
  //       alert("This product cannot be removed from wishlist yet,try again");
  //     }
  //   }
  // };
  const calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (price / 100) * commissionAmount + price;
    } else if (isFixed) {
      return price + commissionAmount;
    } else {
      return price;
    }
  };

  const handlePagination = (event, value) => {
    setPageNumber(value);
    history.push(`/shop/${params.id}/${value}/${choosedLocation}`);
  };

  return (
    <>
      <Helmet>
        <title>Shop</title>
        <meta
          name="Spada Traders shop page"
          content="Spada Traders Shop Page"
        />
      </Helmet>
      <Container>
        <Row className="shop_columns">
          <Col lg={2} md={12}>
            <ShopFilterSection
              // id={this.state.categoryId}
              handleFilters={handleFilters}
              subCategory={subCategory}
              getSubcategory={() => getSubcategoryByCategoryId(params.id)}
              handleSubCategoryId={handleSubCategoryId}
              brand={brand}
              activeItemIndex={activeItemIndex}
              setMinMaxPrice={setMinMaxPrice}
              filterData={filterData}
              handleToggleBrand={handleToggleBrand}
              checkedBrand={checkedBrand}
              handleToggleLocation={handleToggleLocation}
              checkedLocation={checkedLocation}
              handleToggleSubCategories={handleToggleSubCategories}
              checkedSubCategories={checkedSubCategories}
            />
          </Col>
          <Col
            lg={10}
            md={12}
            sm={12}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div style={{ marginBottom: "1rem" }}>
              <h1 className="top-heading">
                <b className="text-capitalize">{singleCategory?.name}</b>
              </h1>
              <p className="top-para">
                Shop&nbsp;
                {subCategory
                  ?.slice(0, 3)
                  .map(
                    (subcat) => subcat.subCategoryName.toLowerCase() + ",  "
                  )}
                and more
              </p>
            </div>
            <hr />
            {/* <div className="category-heading">SHOP BY CATEGORY</div>
            <Row>
              {this.state.categories.map((category) => (
                <Col lg={3} md={4} sm={6}>
                  <p className="category-title">{category.title}</p>
                  <img
                    src={category.image}
                    alt="category"
                    className="category-image"
                  />
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <div className="result-info-bar">
                  <div>
                    <p>
                      1-24 of over 100,000 results for{" "}
                      <span style={{ color: "#c45500" }}>
                        Computers
                      </span>
                    </p>
                  </div>
                  <div>
                    <form>
                      <label for="sort">Sort by</label>
                      <select id="sort" name="sort">
                        <option value="Featured">Featured</option>
                        <option value="Price: Low to High">
                          Price: Low to High
                        </option>
                        <option value="Price: High to Low">
                          Price: High to Low
                        </option>
                        <option value="Avg. Customer Review">
                          Avg. Customer Review
                        </option>
                        <option value="Newest Arrivals">Newest Arrivals</option>
                      </select>
                    </form>
                  </div>
                </div>
              </Col>
            </Row> */}
            {/* <Row> */}
            {productLoader ? (
              <div className="loaders">
                <CircularProgress />
              </div>
            ) : (
              <Row
                style={{
                  marginTop: "24px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                {products?.length > 0 ? (
                  products.map((productdata, i) => (
                    <Col lg={4} md={6} sm={12} key={i}>
                      <ShopProductCard
                        id={productdata._id}
                        src={productdata.image[0]}
                        alt={productdata.name}
                        title={productdata.name}
                        brand={productdata.brand}
                        price={calculatePrice(
                          productdata.isPercentage,
                          productdata.isFixed,
                          productdata.price,
                          parseInt(productdata.commissionAmount)
                        )}
                        stock={productdata.countInStock}
                        ratingValue={productdata.averageRating}
                        link={`/product-detail/${productdata._id}`}
                        userID={productdata.userID}
                        // wish={productdata}
                        // deleteWishlistProduct={() =>
                        //   this.deleteWishlistProduct(productdata)
                        // bestSeller={productdata.bestSeller}
                        // title={productdata.title}
                        // ratingValue={productdata.ratingValue}
                        // NoOfReviews={productdata.NoOfReviews}
                        // addToCart={() => this.addToCart(i)}
                      />
                    </Col>
                  ))
                ) : (
                  <Col md={4} sm={6}>
                    <h4>No Product found</h4>
                  </Col>
                )}
                {products?.length > 0 ? (
                  <Col md={12} sm={12} lg={12}>
                    <PaginationControlled
                      pageNumber={pageNumber}
                      productsLength={Math.ceil(productsLength / 9)}
                      handlePagination={handlePagination}
                    />
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(Shop);
