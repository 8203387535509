import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactInputMask from "react-input-mask";
import "./Profile.css";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import { axiosClient } from "../../helper";

class ManageProfile extends Component {
  state = {
    fullName: "",
    email: "",
    phone: "",
    password: "",
    address: "",
    province: "",
    provinceName: "",
    city: "",
    postalCode: "",
    provinces: [],
    cities: [],
    isEditName: false,
    isEditEmail: false,
    isEditPhone: false,
    isEditAddress: false,
    isEditProvince: false,
    isEditCity: false,
    isEditPostalCode: false,
    isLoading: false,
  };

  componentDidMount() {
    this.getUserById();
    this.getAllProvinces();
  }

  getUserById = () => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("userId")}`)
      .then((response) => {
        let user = response?.data?.user;
        this.setState({
          fullName: user?.name,
          email: user?.email,
          phone: user?.phone,
          address: user?.address,
          city: user?.city,
          province: user?.provinceId?._id,
          provinceName: user?.provinceId?.provinceName,
          postalCode: user?.postalCode,
        });
        this.getCitiesByProvinceId(user?.provinceId?._id);

        if (!user?.address) {
          this.setState({ isEditAddress: true });
        }
        if (!user?.phone) {
          this.setState({ isEditPhone: true });
        }
        if (!user?.provinceId) {
          this.setState({ isEditProvince: true });
        }
        if (!user?.city) {
          this.setState({ isEditCity: true });
        }
        if (!user?.postalCode) {
          this.setState({ isEditPostalCode: true });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllProvinces = () => {
    axiosClient()
      .get(`/getAllProvince`)
      .then((response) => {
        this.setState({ provinces: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCitiesByProvinceId = (id) => {
    axiosClient()
      .get(`/getCitiesByProvinceId/${id}`)
      .then((response) => {
        this.setState({ cities: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "province") {
      this.getCitiesByProvinceId(e.target.value);
      var index = this.state.provinces?.findIndex(
        (province) => province._id === e.target.value
      );
      this.setState({
        provinceName: this.state.provinces[index]?.provinceName,
      });
    }
  };
  enableEdit = (value) => {
    this.setState({ [value]: true });
  };

  updateDetailsEnable = async (value) => {
    if (value === "isEditPhone") {
      if (this.state.phone.replace(/-|_/g, "").length < 15) {
        return toast.error("Please enter a valid phone number");
      } else {
        await this.updateDetails();
        this.setState({ [value]: false });
      }
    } else {
      await this.updateDetails();
      this.setState({ [value]: false });
    }
    if (value === "isEditName") {
      localStorage.setItem("userName", this.state.fullName);
    }
  };

  updateDetails = async () => {
    let userData = {
      name: this.state.fullName,
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
      provinceId: this.state.province,
      city: this.state.city,
      postalCode: this.state.postalCode,
    };
    if (!this.state.fullName || !this.state.email || !this.state.phone) {
      toast.error("Name, email or phone cannot be empty");
    } else {
      this.setState({ isLoading: true });
      await axiosClient()
        .put(`/updateBuyer/${localStorage.getItem("userId")}`, userData)
        .then((response) => {
          this.setState({ isLoading: false });
          toast.success(response?.data?.message);
          return "a";
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          toast.error(error?.response?.data?.message);
        });
    }
  };

  render() {
    console.log("province: ", this.state.phone);
    return (
      <Container>
        {this.state.isLoading ? (
          <div className="post_loader">
            <CircularProgress />
          </div>
        ) : (
          ""
        )}
        <ToastContainer autoClose={2000} />
        <Row>
          <Col sm={2} md={2} lg={2} className="pl-0">
            <DashboardSidenav />
          </Col>
          <Col sm={10} md={10} lg={10}>
            <h1 className="page-heading">Manage Profile</h1>
            <div
              className={
                this.state.isLoading
                  ? "edit_profile_div load_opacity"
                  : "edit_profile_div"
              }
            >
              <Form>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditName ? (
                        <Form.Control
                          type="text"
                          name="fullName"
                          value={this.state.fullName}
                          onChange={this.onChangeInput}
                        />
                      ) : (
                        this.state.fullName
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditName
                            ? () => this.updateDetailsEnable("isEditName")
                            : () => this.enableEdit("isEditName")
                        }
                      >
                        {this.state.isEditName ? "Save" : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditEmail ? (
                        <Form.Control
                          type="text"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeInput}
                        />
                      ) : (
                        this.state.email
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditEmail
                            ? () => this.updateDetailsEnable("isEditEmail")
                            : () => this.enableEdit("isEditEmail")
                        }
                      >
                        {this.state.isEditEmail ? "Save" : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone #</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditPhone || !this.state.phone ? (
                        <ReactInputMask
                          mask={"+\\92 999 9999999"}
                          alwaysShowMask={false}
                          type="text"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.onChangeInput}
                          placeholder="Enter phone number"
                        >
                          {() => (
                            <Form.Control
                              name="phone"
                              value={this.state.phone}
                            />
                          )}
                        </ReactInputMask>
                      ) : !this.state.isEditPhone && this.state.phone ? (
                        this.state.phone
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditPhone
                            ? () => this.updateDetailsEnable("isEditPhone")
                            : () => this.enableEdit("isEditPhone")
                        }
                      >
                        {this.state.isEditPhone || !this.state.phone
                          ? "Save"
                          : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditAddress || !this.state.address ? (
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Enter address"
                          value={this.state.address}
                          onChange={this.onChangeInput}
                        />
                      ) : !this.state.isEditAddress && this.state.address ? (
                        this.state.address
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditAddress
                            ? () => this.updateDetailsEnable("isEditAddress")
                            : () => this.enableEdit("isEditAddress")
                        }
                      >
                        {this.state.isEditAddress || !this.state.address
                          ? "Save"
                          : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Province</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditProvince || !this.state.province ? (
                        <Form.Control
                          as="select"
                          name="province"
                          placeholder="Province"
                          value={this.state.province}
                          onChange={this.onChangeInput}
                        >
                          <option value="" disabled />
                          {this.state.provinces?.map((province) => (
                            <option key={province._id} value={province._id}>
                              {province.provinceName}
                            </option>
                          ))}
                        </Form.Control>
                      ) : !this.state.isEditProvince &&
                        this.state.provinceName ? (
                        this.state.provinceName
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditProvince
                            ? () => this.updateDetailsEnable("isEditProvince")
                            : () => this.enableEdit("isEditProvince")
                        }
                      >
                        {this.state.isEditProvince || !this.state.province
                          ? "Save"
                          : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditCity || !this.state.city ? (
                        <Form.Control
                          as="select"
                          name="city"
                          placeholder="City"
                          value={this.state.city}
                          onChange={this.onChangeInput}
                        >
                          <option value="" disabled />
                          {this.state.cities?.map(
                            (cit) => (
                              console.log("cities: ", this.state.cities),
                              (
                                <option key={cit._id} value={cit.cityName}>
                                  {cit.cityName}
                                </option>
                              )
                            )
                          )}
                        </Form.Control>
                      ) : !this.state.isEditCity && this.state.city ? (
                        this.state.city
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditCity
                            ? () => this.updateDetailsEnable("isEditCity")
                            : () => this.enableEdit("isEditCity")
                        }
                      >
                        {this.state.isEditCity || !this.state.city
                          ? "Save"
                          : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Postal Code</Form.Label>
                  <Row>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      style={{ alignSelf: "center" }}
                    >
                      {this.state.isEditPostalCode ? (
                        <Form.Control
                          type="text"
                          name="postalCode"
                          value={this.state.postalCode}
                          onChange={this.onChangeInput}
                        />
                      ) : (
                        this.state.postalCode
                      )}
                    </Col>
                    <Col
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      style={{ alignSelf: "center" }}
                    >
                      <Button
                        className="edit_buttons"
                        onClick={
                          this.state.isEditPostalCode
                            ? () => this.updateDetailsEnable("isEditPostalCode")
                            : () => this.enableEdit("isEditPostalCode")
                        }
                      >
                        {this.state.isEditPostalCode ? "Save" : "Edit"}
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ManageProfile;
