import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { ToastContainer } from "react-toastify";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactInputMask from "react-input-mask";
// import Autocomplete from "@material-ui/lab/Autocomplete";

const AddressForm = (props) => {
  const {
    fullName,
    setFullName,
    phone,
    setPhone,
    address,
    setAddress,
    city,
    state,
    zipCode,
    setZipCode,
    isRememberAddres,
    handleChange,
    // onChangeCity,
    // onChangeState,
    // cities,
    // province,
    // regionNames,
  } = props;

  return (
    <React.Fragment>
      <ToastContainer />
      <Typography variant="h6" gutterBottom>
        Select a shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            label="Full Name"
            error={!fullName}
            helperText={fullName === "" ? "Full Name is Required" : ""}
            id="lastName"
            name="name"
            fullWidth
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ReactInputMask
            mask={"+\\92 999 9999999"}
            alwaysShowMask={true}
            type="text"
            name="phone"
            id="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          >
            {() => (
              <TextField
                required
                name="phone"
                id="Phone"
                value={phone}
                error={!phone || phone.replace(/-|_/g, "").length < 15}
                helperText={
                  !phone
                    ? "Phone Number is Required"
                    : phone.replace(/-|_/g, "").length < 15
                    ? "Please enter valid phone number"
                    : ""
                }
                label="Phone"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            required
            error={!address}
            helperText={address === "" ? "Address is Required" : ""}
            id="address"
            name="address"
            label="Address line 1"
            value={address}
            fullWidth
            onChange={(e) => setAddress(e.target.value)}
            autoComplete="shipping address-line1"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          {/* {city === "All" ? (
            <Autocomplete
              required
              id="auto-complete-province"
              className="auto-complete-inputs"
              autoComplete
              error={!state}
              helperText={state === "" ? "State is Required" : ""}
              value={state}
              name="state"
              onChange={onChangeState}
              options={regionNames}
              getOptionLabel={(option) =>
                option.title ? option.title : option
              }
              getOptionSelected={(option) =>
                option.title ? option.title : option
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Province/Region"
                  margin="normal"
                />
              )}
            />
          ) : ( */}
          <TextField
            required
            error={!state}
            helperText={state === "" ? "Region is Required" : ""}
            id="region"
            name="region"
            label="Region"
            value={state}
            fullWidth
            autoComplete="state"
            disabled={true}
          />
          {/* )} */}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          {/* {city === "All" ? (
            <Autocomplete
              required
              id="auto-complete-city"
              className="auto-complete-inputs"
              autoComplete
              error={!city}
              helperText={city === "" ? "City is Required" : ""}
              name="city"
              value={city}
              options={cities}
              getOptionLabel={(option) => (option.title ? option.title : "")}
              getOptionSelected={(option) => (option.title ? option.title : "")}
              fullWidth
              onChange={onChangeCity}
              renderInput={(params) => (
                <TextField {...params} label="City" margin="normal" />
              )}
            />
          ) : ( */}
          <TextField
            required
            error={!city}
            helperText={city === "" ? "City is Required" : ""}
            id="choosedLocation"
            name="choosedLocation"
            label="City"
            value={city}
            fullWidth
            autoComplete="choosedLocation"
            disabled={true}
          />
          {/* )} */}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="zip"
            type="number"
            value={zipCode}
            name="zipCode"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            onChange={(e) => setZipCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRememberAddres}
                  onChange={handleChange}
                  value={isRememberAddres}
                  name="isRememberAddres"
                  color="primary"
                />
              }
              label="Save this information for next time"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default AddressForm;
