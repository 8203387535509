import React, { Component } from "react";
import "./WishList.css";
import { Container, Row, Col } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import SearchProductCard from "../../components/searchProductCard/SearchProductCard";
import { axiosClient } from "../../helper";

class WishList extends Component {
  state = {
    wishlist: [],
    loader: true,
  };
  componentDidMount() {
    this.getWishlist();
  }
  deleteWishlistProduct = async (wish, productid) => {
    const OriginalWishlist = this.state.wishlist;
    const wishlist = this.state.wishlist.filter((w) => w._id !== wish._id);
    this.setState({ wishlist });
    try {
      await axiosClient().post(`/removeProductFromWishList/${wish._id}`, {
        productId: productid,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
      }
      this.setState({ wishlist: OriginalWishlist });
    }
  };
  getWishlist = () => {
    axiosClient()
      .get("/getWishListOfLoggedInBuyer")
      .then((response) => {
        this.setState({ loader: false });
        if (response.status === 200) {
          if (response.data.wishList.length <= 0) {
            this.setSetstate({ wishlist: [] });
          } else {
            this.setState({ wishlist: response.data.wishList });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (price / 100) * commissionAmount + price;
    } else if (isFixed) {
      return price + commissionAmount;
    } else {
      return price;
    }
  };
  render() {
    return (
      <Container>
        <Row>
          <Col sm={2} md={2} lg={2} className="pl-0">
            <DashboardSidenav />
          </Col>
          <Col sm={10} md={10} lg={10}>
            <h1 className="page-heading">Wishlist</h1>
            {this.state.loader ? (
              <div className="loaders">
                <CircularProgress />
              </div>
            ) : (
              <>
                {this.state.wishlist.length > 0 ? (
                  this.state.wishlist.map((wish) => (
                    <SearchProductCard
                      src={wish.productId.image[0]}
                      title={wish.productId.name}
                      link={`/product-detail/${wish.productId._id}`}
                      brand={wish.productId.brand}
                      // price={wish.productId.price}
                      price={this.calculatePrice(
                        wish.productId.userID.isPercentage,
                        wish.productId.userID.isFixed,
                        wish.productId.price,
                        wish.productId.userID.commissionAmount
                      )}
                      ratingValue={wish.productId.averageRating}
                      NoOfReviews={wish.productId.averageRating}
                      showDelete={true}
                      wishlist={wish}
                      userID={wish.productId.userID._id}
                      deleteWishlistProduct={() =>
                        this.deleteWishlistProduct(wish, wish.productId._id)
                      }
                    />
                  ))
                ) : (
                  <div style={{}} className="no_product_wishlist">
                    No products in your wishlist
                    <br />
                    Add products to your wishlist to see here
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WishList;
