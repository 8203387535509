import React, { useState } from "react";
import "./Login.css";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SpadaLoader from "./assets/loader/spadal.mp4";

import CircularProgress from "@material-ui/core/CircularProgress";
// import { auth } from "./firebase";
import { axiosClient } from "./helper";
import deosaiLogo from "./assets/desosaiLogo.png";
import SpadaSoftLogo1 from "./assets/logos/spada-traders.png";

function Login() {
  const [useremail, setEmail] = useState("");
  const [userpassword, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [spadaLoader, setSpadaLoader] = useState(false);

  const history = useHistory();

  const handleSignin = (e) => {
    e.preventDefault();
    const loginInfo = {
      email: useremail,
      password: userpassword,
    };
    if (!useremail || !userpassword) {
      toast.error("Email & password is required");
    } else {
      setLoader(true);
      setSpadaLoader(true);
      axiosClient()
        .post("/buyerLogin", loginInfo)
        .then((res) => {
          if (res.status === 200) {
            setLoader(false);
            setSpadaLoader(false);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.user._id);
            localStorage.setItem("userName", res.data.user.name);
            let d = new Date();
            d = d.getTime();
            let date = d + 170000000;
            localStorage.setItem("tokenExpiry", date);
            toast.success("loggedin");
            history.push("/");
          }
        })
        .catch((error) => {
          setLoader(false);
          setSpadaLoader(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  // const signUpAsGuest = () => {
  //   axiosClient()
  //     .post("/signupGuest")
  //     .then((res) => {
  //       localStorage.setItem("token", res.data.token);
  //       localStorage.setItem("userId", res.data.guest._id);
  //       localStorage.setItem("userName", res.data.guest.name);
  //       history.push("/");
  //       alert("Login in as Guest");
  //     })
  //     .catch((err) => {
  //       console.log(err?.response?.data?.message);
  //     });
  // };

  const register = (e) => {
    e.preventDefault();
    history.push("/signup");
  };

  return (
    <>
      {spadaLoader ? (
        <div className="post_loader">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <div className={spadaLoader ? "login load_opacity" : "login"}>
        <ToastContainer autoClose={3000} />
        <Link to="/">
          <img
            style={{
              objectFit: "contain",
              width: "180px",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "0px",
              marginBottom: "0px",
            }}
            // className="signup__logo"
            src={SpadaSoftLogo1}
            alt=""
          />
        </Link>

        <div className="login__container">
          <h1>Sign in</h1>
          <form action="">
            <h6>Email</h6>
            <input
              type="email"
              className="setInputField"
              name="email"
              value={useremail}
              onChange={(e) => setEmail(e.target.value)}
            />
            <h6>Password</h6>
            <input
              type="password"
              name="password"
              className="setInputField"
              value={userpassword}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button onClick={handleSignin} className="login__signInButton">
              Sign In
            </button>
          </form>
          <Link to="/email-verify" className=" forgot">
            Forgot Password ?
          </Link>
          <p>
            By continuing, you agree to Spada Traders's Terms & Conditions of
            use and Privacy Policy.
          </p>
          <button onClick={register} className="login__registerButton">
            Create your Spada Traders Account
          </button>{" "}
          {/* <span style={{ textAlign: "center", margin: "4px" }}>OR</span>
          <button
            onClick={signUpAsGuest}
            className="login__registerButton"
            style={{ marginTop: "0px" }}
          >
            Sign up as Guest
          </button> */}
        </div>
      </div>
    </>
  );
}

export default Login;
