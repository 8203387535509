import React from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Subtotal() {
  const history = useHistory();
  const [{ basket, totalShipping }, dispatch] = useStateValue();

  return (
    <div className="subtotal">
      <ToastContainer />
      <CurrencyFormat
        renderText={(value) =>
          basket.length ? (
            (console.log("value: ", value, totalShipping),
            (
              <>
                <p>
                  Subtotal ({basket?.length} items):{" "}
                  <strong>PKR {value}</strong>
                </p>
                <p>
                  Shipping Price is
                  <strong>
                    {" "}
                    PKR {totalShipping > 0 ? totalShipping : 0.0}
                  </strong>
                </p>
                <p>
                  Total cost will be{" "}
                  <strong>PKR {Number(value) + Number(totalShipping)}</strong>
                  &nbsp;
                  <span>(excl. tax)</span>
                </p>
              </>
            ))
          ) : (
            <p>Add products</p>
          )
        }
        decimalScale={2}
        value={getBasketTotal(basket)}
        displayType={"text"}
      />
      <button
        style={{ fontWeight: "bold", height: "40px" }}
        onClick={() => {
          if (
            localStorage.getItem("token") === undefined ||
            localStorage.getItem("token") === null
          )
            return history.push("/login");
          if (basket.length < 1) {
            return toast.error("Add products to proceed");
          } else if (basket.quantity < basket.minimumQuantity) {
            toast.error(
              `Quantity cannot be less than ${basket.minimumQuantity}`
            );
          } else {
            return history.push("/check-out");
          }
        }}
      >
        Proceed to Checkout
      </button>
    </div>
  );
}

export default Subtotal;
