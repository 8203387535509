import React, { Component } from "react";
import "./IWant.css";
import { Container, Row, Col, Form, Carousel, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Input from "@material-ui/core/Input";
// import sli from "../../assets/bg.jpg";
// import { iwantRequest } from "./store/actions";
import { axiosClient } from "../../helper";
import ReferencePopup from "../../components/referencePopup/referencePopup";

class IWant extends Component {
  state = {
    productName: "",
    description: "",
    customerName: "",
    email: "",
    contactNo: "",
    regions: [],
    show: false,
    selectedRegion: "",
    city: "",
    iWantId: "",
  };
  componentDidMount() {
    if (localStorage.getItem("token") && localStorage.getItem("userId")) {
      this.getUserById();
    }
    this.getAllRegions();
  }

  handleClose = () =>
    this.setState({
      show: false,
      productName: "",
      description: "",
      selectedRegion: "",
      city: "",
    });

  handleShow = () => this.setState({ show: true });

  getUserById = () => {
    axiosClient()
      .get(`/getUsersById/${localStorage.getItem("userId")}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            customerName: response.data.user.name,
            email: response.data.user.email,
            contactNo: response.data.user.phone,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllRegions = () => {
    axiosClient()
      .get(`/getAllProvince`)
      .then((response) => {
        this.setState({ regions: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChangeInput = (e) => {
    // if (e.target.name === "contactNo") {
    //   if (e.target.value.replace(/-|_/g, "").length < 15) {
    //     return toast.error("Phone number is not valid");
    //   } else {
    //     this.setState({ [e.target.name]: e.target.value });
    //   }
    // } else {
    this.setState({ [e.target.name]: e.target.value });
    // }
  };

  handleSubmitProduct = () => {
    let iwantData = {
      productName: this.state.productName,
      description: this.state.description,
      customerName: this.state.customerName,
      email: this.state.email,
      contactNo: this.state.contactNo,
      location: this.state.selectedRegion,
      city: this.state.city,
      buyerId: localStorage.getItem("userId"),
    };
    if (
      !this.state.productName ||
      !this.state.description ||
      !this.state.email ||
      !this.state.contactNo ||
      !this.state.customerName ||
      !this.state.selectedRegion ||
      !this.state.city
    ) {
      return toast.error("Please fill all required fields");
    }
    if (this.state.contactNo.replace(/-|_/g, "").length < 15) {
      return toast.error("Please enter a valid phone number");
    } else {
      this.iwantRequest(iwantData);
    }
  };

  iwantRequest = (iwantData) => {
    return axiosClient()
      .post("/iWantRequest", iwantData)
      .then((response) => {
        this.setState({ iWantId: response.data.data._id });
        this.handleShow();
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
      });
  };

  render() {
    return (
      <div className="iwant-container">
        <ToastContainer autoClose={3000} />
        <Container>
          <div className="iwant-heading-div">
            <h3 className="iwant-heading">iWant</h3>
            <span>Describe the product you want</span>
          </div>
          <Row>
            <Col sm={12} md={12} lg={6} className="">
              <div className="carousel-div carousel-bg">
                <Carousel indicators={false} interval={120000}>
                  <Carousel.Item>
                    {/* <img
                      className="d-block w-100"
                      src={sli}
                      alt="First slide"
                    /> */}
                    <Carousel.Caption>
                      <h3>iWant</h3>
                      <p>
                        You can request new products to be added that you can't
                        find in Spada Traders
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    {/* <img
                      className="d-block w-100"
                      src={sli}
                      alt="Third slide"
                    /> */}

                    <Carousel.Caption>
                      <h3>Contact Details</h3>
                      <p>
                        Provide your contact details so we can let you know when
                        your requested product is available
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    {/* <img
                      className="d-block w-100"
                      src={sli}
                      alt="Third slide"
                    /> */}

                    <Carousel.Caption>
                      <h3>Product Details</h3>
                      <p>
                        Describe product so we can get it according to your
                        requirements
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
            <Col sm={12} md={12} lg={5}>
              <div className="iwant-form-div">
                <Form>
                  <Form.Group controlId="customername">
                    <Form.Label>
                      Your Name <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="customerName"
                      value={this.state.customerName}
                      onChange={this.handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Label>
                      Email <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChangeInput}
                    />
                  </Form.Group>

                  <Form.Group controlId="contactno">
                    <Form.Label>
                      Contact # <span className="required">*</span>
                    </Form.Label>
                    <ReactInputMask
                      mask={"+\\92 999 9999999"}
                      alwaysShowMask={false}
                      type="text"
                      name="contactNo"
                      id="contactNo"
                      value={this.state.contactNo}
                      onChange={this.handleChangeInput}
                    >
                      {() => (
                        <Form.Control
                          name="contactNo"
                          value={this.state.contactNo}
                        />
                      )}
                    </ReactInputMask>
                  </Form.Group>
                  <Form.Group controlId="productname">
                    <Form.Label>
                      Product Name <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="productName"
                      value={this.state.productName}
                      onChange={this.handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>
                      Description <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChangeInput}
                    />
                  </Form.Group>
                  <Form.Group controlId="location">
                    <Form.Label>
                      Region <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="selectedRegion"
                      value={this.state.selectedRegion}
                      onChange={this.handleChangeInput}
                    >
                      <option disabled value="" />
                      {this.state.regions?.map((region) => (
                        <option key={region._id} value={region.provinceName}>
                          {region.provinceName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="city">
                    <Form.Label>
                      City <span className="required">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={this.state.city}
                      onChange={this.handleChangeInput}
                    />
                  </Form.Group>
                </Form>
                <Button
                  className="submit-button"
                  onClick={this.handleSubmitProduct}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <ReferencePopup
          handleClose={this.handleClose}
          show={this.state.show}
          referenceId={this.state.iWantId}
        />
      </div>
    );
  }
}
export default IWant;
