import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import { Container, Row, Col } from "react-bootstrap";
import "./MyOrders.css";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import { axiosClient } from "../../helper";
import OrderRow from "./MyOrderRow";
import { withRouter } from "react-router-dom";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className="flex-shrink-0">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

function MyOrders(props) {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders?.length - page * rowsPerPage);

  useEffect(() => {
    getAllOrdersByUserId();
  }, []);

  const getAllOrdersByUserId = () => {
    axiosClient()
      .get(`/getOrdersByBuyerId/${localStorage.getItem("userId")}`)
      .then((response) => {
        setLoader(false);
        setOrders(response.data.orders);
        // if (response.status === 200) {
        //   response.data.orders.forEach((element, i) => {
        //     element.orderItems.forEach((ele) => {
        //       axiosClient()
        //         .get(
        //           `/getOrderStatusBySellerAndOrderId/${element._id}/${ele.sellerId._id}`
        //         )
        //         .then((res) => {
        //           ele["orderStatus"] = res.data.orderStatus[0].status;
        //           setOrders((prev) => [...prev, element]);
        //         })
        //         .catch((error) => {
        //           console.log(error);
        //         });
        //     });
        //   });
        // }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10}>
          <h1 className="page-heading">My Orders</h1>
          {loader ? (
            <div className="loaders">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="my_orders">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="main_rows">
                    <TableCell />
                    <TableCell>Order Id</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Total Price</TableCell>
                    {/* <TableCell>Actions</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? orders?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : orders
                  )?.map((order) => {
                    return <OrderRow key={order._id} order={order} />;
                  })}
                  {/* : (<h5 className="no_orders">No orders found</h5>) */}
                  {orders.length <= 0 ? (
                    <h5 className="no_orders">No orders found</h5>
                  ) : (
                    ""
                  )}
                  {emptyRows >= 10 && (
                    <TableRow style={{ height: 70 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter className="main_rows">
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={orders ? orders.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(MyOrders);
