import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import CardContent from "@material-ui/core/CardContent";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams, Link } from "react-router-dom";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";
import SpadaLoader from "../../assets/loader/spadal.mp4";

import { axiosClient } from "../../helper";
import "./invoice.css";

export default function InvoiceViewer(props) {
  const buyerId = localStorage.getItem("userId");
  const params = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [orderId, setOrderId] = useState({});
  const [loader, setLoader] = useState(true);
  const [spadaLoader, setSpadaLoader] = useState(false);

  const [orderItems, setOrderItems] = useState([]);

  const id = params.id;
  useEffect(() => {
    setSpadaLoader(true);
    axiosClient()
      .get(`/getInvoiceByInvoiceId/${id}`)
      .then((res) => {
        setLoader(false);
        setSpadaLoader(false);
        setInvoiceData(res.data.data);
        setOrderId(res.data.data.orderId);
        setOrderItems(res.data.data.orderId.orderItems);
      })
      .catch((error) => {
        setLoader(false);
        setSpadaLoader(false);
        console.log(error);
      });
  }, []);

  return (
    <Container>
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10}>
          <h1 className="page-heading">Invoice Details</h1>
          {spadaLoader ? (
            <div className="loaders">
              <img src={SpadaLoader} className="spadaloader-image" />
            </div>
          ) : (
            <div className="invoice-details">
              <Card variant="outlined">
                <CardContent style={{ paddingTop: "24px" }}>
                  <div className="invoice-viewer py-16 pl-3px">
                    <div className="viewer_actions px-3 mb-3 d-flex align-items-center justify-content-between">
                      <Link
                        to={`/my-invoices/${buyerId}`}
                        style={{ marginTop: "-8px" }}
                      >
                        <i className="i-Left1 text-20 font-weight-700">
                          <KeyboardBackspaceIcon />{" "}
                        </i>
                      </Link>
                      <div>
                        <Button
                          onClick={() => window.print()}
                          variant="warning"
                          style={{ fontSize: "12px" }}
                        >
                          Print Invoice
                        </Button>
                      </div>
                    </div>

                    <div id="print-area" className="px-3">
                      <div className="row">
                        <div className="col-md-6">
                          <h5 style={{ fontWeight: "bold" }}>Order Info</h5>
                          {orderId.refId}
                        </div>
                        <div className="col-md-6 text-sm-right">
                          <p className="text-capitalize">
                            <strong>Order status: </strong>
                            {invoiceData.status}
                          </p>
                          <p>
                            <strong>Order date: </strong>
                            <span>
                              {new Date(orderId.createdAt).toLocaleDateString()}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="mt-3 mb-4 border-top"></div>
                      <div className="row mb-4">
                        <div className="col-sm-12 text-sm-right">
                          <h6 className="font-weight-bold">Bill To</h6>
                          {orderId.customerName}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 table-responsive">
                          <table className="table mb-4 invoice-details-table">
                            <thead>
                              <tr>
                                <th style={{ maxWidth: "130px" }}>
                                  Item Description{" "}
                                </th>
                                <th>Item SKU</th>
                                <th>Seller Name</th>
                                <th>Seller Address</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th>Cost</th>
                                <th>Tax</th>
                                <th>Delivery Charges</th>
                                <th>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderId.orderItems?.map((data) => (
                                <tr>
                                  <td style={{ maxWidth: "130px" }}>
                                    {/* <strong> {data.quantity} x </strong> */}
                                    {data.productName}
                                  </td>
                                  <td>{data.productId.SKU}</td>
                                  <td>
                                    {data.sellerId.fullName ? (
                                      data.sellerId.fullName
                                    ) : data.sellerId.name ? (
                                      data.sellerId.name
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </td>
                                  <td>
                                    {data.sellerId.address ? (
                                      data.sellerId.address
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </td>
                                  <td>{data.quantity}</td>
                                  <td>{data.unitPrice}&nbsp;rs/-</td>
                                  <td>
                                    {data.quantity * data.unitPrice}
                                    rs/-
                                  </td>
                                  <td>{data.tax}&nbsp;rs/-</td>
                                  <td>
                                    {data.productShippingCharges}&nbsp;rs/-
                                  </td>
                                  <td>
                                    {data.quantity * data.unitPrice +
                                      data.productShippingCharges +
                                      data.tax}
                                    rs/-
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="d-flex justify-content-end">
                            <h6 className="font-weight-bold fs-14">
                              Products Total:
                            </h6>
                            <span className="ml-2 fs-14">
                              {orderItems.reduce(
                                (a, v) => (a = a + v.quantity * v.unitPrice),
                                0
                              )}
                              &nbsp;rs/-
                            </span>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="d-flex justify-content-end">
                            <h6 className="font-weight-bold fs-14">
                              Shipping Charges:
                            </h6>
                            <span className="ml-2 fs-14">
                              {orderItems.reduce(
                                (a, v) => (a = a + v.productShippingCharges),
                                0
                              )}
                              &nbsp;rs/-
                            </span>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="d-flex justify-content-end">
                            <h6 className="font-weight-bold fs-14">
                              Total Tax:
                            </h6>
                            <span className="ml-2 fs-14">
                              {orderItems.reduce((a, v) => (a = a + v.tax), 0)}
                              &nbsp;rs/-
                            </span>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12">
                          <div className="d-flex justify-content-end">
                            <h6 className="font-weight-bold fs-14">
                              Total Cost:
                            </h6>
                            <span className="ml-2 fs-14">
                              {Math.round(orderId.totalPrice)} rs/-
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
