import FeatureImg01 from "../assets/blog-card-img.jpg";
import FeatureImg02 from "../assets/feature-image.png";
export const featureData = [
  { featureImage: FeatureImg01 },
  { featureImage: FeatureImg02 },
  { featureImage: FeatureImg01 },
  { featureImage: FeatureImg01 },
  { featureImage: FeatureImg01 },
  { featureImage: FeatureImg01 },
];

export const blogsData = [{}, {}, {}, {}, {}, {}];
