import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./index.css";
const PopUp = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="city-popup"
      >
        <Modal.Header>
          <Modal.Title>Request Submitted Successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3
            style={{
              fontWeight: "revert",
              fontStyle: "oblique",
              marginBottom: "20px",
              fontStretch: "condensed",
            }}
          >
            Thank you
          </h3>
          <span style={{ fontSize: "15px" }}>
            Below is the reference id for your request
          </span>

          <p className="request_id">{props.referenceId}</p>
          <span className="request_received">
            We have received your request and we will get back to you soon...
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button className="request_footer_btn" onClick={props.handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PopUp;
