import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { axiosClient } from "../../helper";
import DashboardSidenav from "../../components/DashboardSidenav/DashboardSidenav";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className="flex-shrink-0">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

function ViewReturnRequests() {
  const [returnRequests, setReturnRequests] = useState([]);
  // const [status, setStatus] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setloader] = useState(true);
  const [update, setUpdate] = useState(false);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, returnRequests?.length - page * rowsPerPage);

  useEffect(() => {
    let id = localStorage.getItem("userId");
    getReturnRequestsByUserId(id);
  }, [update]);

  const getReturnRequestsByUserId = (id) => {
    axiosClient()
      .get(`/getReturnRequestsByBuyerId/${id}`)
      .then((response) => {
        setloader(false);
        setReturnRequests(response.data.returnRequests);
        // setStatus(response.data.returnRequests.status);
      })
      .catch((error) => {
        setloader(false);
        console.log(error);
      });
  };

  const updateRequestStatus = (event, id) => {
    axiosClient()
      .post(`/changeStatusOfReturnOrderRequestById/${id}`, {
        status: event.target.value,
      })
      .then((response) => {
        setUpdate(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10}>
          <h1 className="page-heading">Return Requests</h1>
          {loader ? (
            <div className="loaders">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="my_orders">
              <Table aria-label="return requests table">
                <TableHead>
                  <TableRow className="main_rows">
                    {/* <TableCell>Seller Name</TableCell> */}
                    <TableCell>Reference #</TableCell>
                    <TableCell>Product Image</TableCell>
                    <TableCell style={{ maxWidth: "200px" }}>
                      Product Name
                    </TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? returnRequests?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : returnRequests
                  )?.map((row, i) => (
                    <TableRow key={row?._id} className="main_rows">
                      {/* <TableCell> */}
                      {/* {row.orderItems.map((p, i) => (
                          <>
                            <span>{p.sellerId.name}</span>
                            <br />
                          </>
                        ))} */}
                      {/* <span>{i + 1}</span>
                      </TableCell> */}
                      <TableCell>{row?._id}</TableCell>
                      <TableCell>
                        <img
                          src={row?.productId.image[0]}
                          alt="image"
                          style={{
                            maxWidth: "50px",
                            maxHeight: "50px",
                            margin: "auto",
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ maxWidth: "200px" }}>
                        <span>
                          <strong>{row?.quantity} x</strong>
                          {` ${row?.productName}`}
                        </span>
                      </TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.reason}</TableCell>
                      <TableCell>
                        {row?.status === "Awaiting" ? (
                          <FormControl>
                            <Select
                              className={
                                row.status === "Awaiting"
                                  ? "awaiting"
                                  : // :
                                    // row.status === "Withdrawal"
                                    // ? "withdrawal"
                                    ""
                              }
                              disableUnderline={true}
                              label={row.status}
                              value={row.status}
                              onChange={(event) => {
                                updateRequestStatus(event, row._id);
                              }}
                            >
                              <MenuItem value="Awaiting">Awaiting</MenuItem>
                              <MenuItem value="Withdrawal">Withdrawal</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <div
                            className={
                              row?.status === "Approved"
                                ? "approved"
                                : row.status === "Rejected"
                                ? "rejected"
                                : row?.status === "Withdrawal"
                                ? "withdrawal"
                                : ""
                            }
                          >
                            {row?.status}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {returnRequests.length <= 0 ? (
                    <h5 className="no_orders">No return requests found</h5>
                  ) : (
                    ""
                  )}

                  {emptyRows >= 10 && (
                    <TableRow style={{ height: 70 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter className="main_rows">
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={returnRequests ? returnRequests.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default ViewReturnRequests;
