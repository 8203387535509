import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  alignment: {
    "& > ul": {
      justifyContent: "center",
    },
  },
}));

function PaginationControlled(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: "30px" }}>
      <Pagination
        count={props.productsLength}
        page={props.pageNumber}
        onChange={props.handlePagination}
        className={classes.alignment}
      />
    </div>
  );
}
export default PaginationControlled;
