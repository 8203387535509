import React from "react";
import BlogsDetails from "../../components/blogsDetails";
import Header from "../../Header";
import Footer from "../../components/Footer/Footer";
export default function BlogDetails() {
  return (
    <>
      <Header />
      <BlogsDetails />
      <Footer />
    </>
  );
}
