import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "justify",
    color: "black",
    lineHeight: 0.8,
  },
  heading: {
    fontSize: "19px !important",
    lineHeight: "32px !important",
  },
}));

const ProductInfo = (props) => {
  const classes = useStyles();
  let item = [props.item];

  return (
    <>
      {item.map((items, i) => {
        return (
          <Paper className="product_specs" key={i}>
            <br />
            <h1
              className={classes.heading}
              style={{
                color: "#B82704",
                borderBottom: "1px solid rgba(0,0,0,.1)",
                paddingBottom: "4px",
                marginBottom: "12px",
              }}
            >
              Product Information
            </h1>
            <Grid container spacing={2} style={{ padding: "0px" }}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className="product_policies policies-mt">
                  <h1 className="about mb-2">Product Properties </h1>
                  <TableContainer
                    component={Paper}
                    className="description-tables pt-1"
                  >
                    <Table
                      className={`pt-2 ${classes.table}`}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Property</TableCell>
                          <TableCell>Value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.color ? (
                          <TableRow>
                            <TableCell>Color</TableCell>
                            <TableCell>{items.color}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {items.productWidth ? (
                          <TableRow>
                            <TableCell>Width</TableCell>
                            <TableCell>
                              {items.productWidth}&nbsp;inches
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {items.productHeight ? (
                          <TableRow>
                            <TableCell>Height</TableCell>
                            <TableCell>
                              {items.productHeight}&nbsp;inches
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {items.productDepth ? (
                          <TableRow>
                            <TableCell>Depth</TableCell>
                            <TableCell>
                              {items.productDepth}&nbsp;inches
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {items.productWeight ? (
                          <TableRow>
                            <TableCell>Weight</TableCell>
                            <TableCell>
                              {items.productWeight}&nbsp;kg(s)
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </>
  );
};

export default ProductInfo;
