import React from "react";
import { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FeatureImage from "../../assets/blog-card-img.jpg";
import FeatureImg from "../../assets/feature-image.png";
import { featureData } from "../../data/data";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./index.css";

export default function index({ data }) {
  // const navigate = useNavigate();

  return (
    <>
      <Grid
        // onClick={() => navigate("/youtube.com")}
        className="feature-card-main"
      >
        <Grid className="feature-card">
          <Grid className="feature-card-1">
            <img
              src={data.image}
              alt="whatsapp-icon"
              className="feature-image"
            />
          </Grid>
          <Grid>
            <Typography className="feature-description">{data.name}</Typography>

            <Typography className="feature-product-price">
              ${data.price}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
