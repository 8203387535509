import React, { useState } from "react";
import "./Signup.css";
import { Link, useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
// import MaskedInput from "react-text-mask";
import CircularProgress from "@material-ui/core/CircularProgress";
import deosaiLogo from "./assets/desosaiLogo.png";
import SpadaLoader from "./assets/loader/spadal.mp4";

import SpadaSoftLogo from "./assets/logos/spada-traders.png";

import ReactInputMask from "react-input-mask";
// import { auth } from "./firebase";
import { axiosClient } from "./helper";

function Signup() {
  const [useremail, setEmail] = useState("");
  const [userpassword, setPassword] = useState("");
  const [username, setName] = useState("");
  const [userphone, setPhone] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [spadaLoader, setSpadaLoader] = useState(false);

  const history = useHistory();

  let errors = [];
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value !== "") {
      setNameErr(null);
    }
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value !== "") {
      setPasswordErr(null);
    }
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== "" || regEmail.test(useremail)) {
      setEmailErr(null);
    }
  };
  const onChangePhone = (e) => {
    setPhone(e.target.value);
    if (e.target.value && e.target.value.replace(/-|_/g, "").length >= 15) {
      setPhoneErr(null);
    }
  };
  const handleValidate = () => {
    if (!username) {
      errors.push(nameErr);
    }

    if (!username) {
      setNameErr("Username is required");
    }

    if (!userpassword) {
      setPasswordErr("Password is required");
      errors.push(passwordErr);
    }

    if (!userphone || userphone.replace(/-|_/g, "").length < 15) {
      errors.push(phoneErr);
    }

    if (!userphone) {
      setPhoneErr("Phone number is required");
    } else if (userphone.replace(/-|_/g, "").length < 15) {
      setPhoneErr("Phone number is invalid");
    }

    if (!useremail || !regEmail.test(useremail)) {
      errors.push(emailErr);
    }
    if (!useremail) {
      setEmailErr("Email is required");
    } else if (!regEmail.test(useremail)) {
      setEmailErr("Email pattern should be 'example@email.com'");
    }
    if (errors.length > 0) {
      return errors.length;
    }
    return errors.length;
  };
  const handleSignup = (e) => {
    e.preventDefault();

    let userDetails = {
      name: username,
      email: useremail,
      password: userpassword,
      phone: userphone,
    };
    // let a = handleValidate();
    if (handleValidate() > 0) {
      toast.error("Please fill all fields correctly");
      return;
    } else {
      setLoader(true);
      setSpadaLoader(true);
      axiosClient()
        .post("/signupBuyer", userDetails)
        .then((response) => {
          setLoader(false);
          setSpadaLoader(false);
          let res = JSON.parse(JSON.stringify(response));
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.buyer._id);
          localStorage.setItem("userName", res.data.buyer.name);
          toast.success("Signedup successfully");
          history.push("/");
        })
        .catch((error) => {
          setLoader(false);
          setSpadaLoader(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  return (
    <>
      {spadaLoader ? (
        <div className="post_loader">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <div className={spadaLoader ? "signup load_opacity" : "signup"}>
        <ToastContainer autoCLose={3000} />
        <Link to="/">
          <img className="spadaloader-image" src={SpadaSoftLogo} alt="" />
        </Link>

        <div className="signup__container">
          <h2>Create account</h2>
          <form action="">
            <Form.Group>
              <h6>
                Name <span className="required">*</span>
              </h6>
              <input
                required
                type="text"
                className="setInputField"
                name="name"
                value={username}
                onChange={onChangeName}
              />
              {nameErr ? (
                <span className="display_error">{nameErr}</span>
              ) : null}
            </Form.Group>
            <Form.Group>
              <h6>
                E-mail <span className="required">*</span>
              </h6>
              <input
                required
                type="text"
                className="setInputField"
                name="email"
                value={useremail}
                onChange={onChangeEmail}
              />
              {emailErr ? (
                <span className="display_error">{emailErr}</span>
              ) : null}
            </Form.Group>
            <Form.Group>
              <h6>
                Password <span className="required">*</span>
              </h6>
              <input
                required
                type="password"
                className="setInputField"
                name="password"
                value={userpassword}
                onChange={onChangePassword}
              />
              {passwordErr ? (
                <span className="display_error">{passwordErr}</span>
              ) : null}
            </Form.Group>
            <Form.Group>
              <h6>Phone</h6>

              <ReactInputMask
                mask={"+\\92 999 9999999"}
                alwaysShowMask={false}
                type="text"
                className="setInputField"
                name="phone"
                value={userphone}
                onChange={onChangePhone}
              >
                {() => (
                  <input
                    name="phone"
                    className="setInputField"
                    value={userphone}
                  />
                )}
              </ReactInputMask>
              {phoneErr ? (
                <span className="display_error">{phoneErr}</span>
              ) : null}
            </Form.Group>
            <button onClick={handleSignup} className="signup__signInButton">
              Sign Up
            </button>
          </form>
          <p>
            Already have an acccount? <Link to="/login">Login</Link>
          </p>
          <p>
            By continuing, you agree to Spada Traders's Terms & Conditions of
            Use and Privacy Policy.
          </p>
        </div>
      </div>
    </>
  );
}

export default Signup;
