import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import { Container, Row, Col } from "react-bootstrap";
import "../../myOrders/MyOrders.css";
import DashboardSidenav from "../../../components/DashboardSidenav/DashboardSidenav";
import { axiosClient } from "../../../helper";
import IwantCollapseRow from "./iwantCollapseRow";
import { withRouter } from "react-router-dom";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className="flex-shrink-0">
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

function IwantRequests(props) {
  const [iwantRequests, setiWantRequests] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, iwantRequests?.length - page * rowsPerPage);

  useEffect(() => {
    getAlliWantRequests();
  }, []);

  const getAlliWantRequests = () => {
    axiosClient()
      .get(`/getiWantRequestByBuyerId/${localStorage.getItem("userId")}`)
      .then((response) => {
        setLoader(false);
        setiWantRequests(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Row>
        <Col sm={2} md={2} lg={2} className="pl-0">
          <DashboardSidenav />
        </Col>
        <Col sm={10} md={10} lg={10}>
          <h1 className="page-heading">iWant Requests</h1>
          {loader ? (
            <div className="loaders">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className="my_orders">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow className="main_rows">
                    <TableCell />
                    <TableCell>Product Name</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? iwantRequests?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : iwantRequests
                  )?.map((iwantRequest) => {
                    return (
                      <IwantCollapseRow
                        key={iwantRequest._id}
                        iwantRequest={iwantRequest}
                      />
                    );
                  })}
                  {iwantRequests.length <= 0 ? (
                    <h5 className="no_orders">No requests found</h5>
                  ) : (
                    ""
                  )}
                  {emptyRows >= 10 && (
                    <TableRow style={{ height: 70 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter className="main_rows">
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      count={iwantRequests ? iwantRequests.length : 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(IwantRequests);
