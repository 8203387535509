import React, { useState } from "react";
import {
  // AiOutlineShoppingCart,
  AiOutlineHeart,
  AiTwotoneHeart,
} from "react-icons/ai";

import { Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ShopProductCard.css";
// import Rating from "@material-ui/lab/Rating";
import { useStateValue } from "../../StateProvider";
import { axiosClient, textTruncate } from "../../helper";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import Button from "@material-ui/core/Button";
// import Grid from "@material-ui/core/Grid";
// import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
// import LocalMallIcon from "@material-ui/icons/LocalMall";

// class ShopProductCard extends Component {
//   render() {
//     return (
//       <div className="product-card-div">
//         <Card>
//           <Card.Body>
//             {this.props.bestSeller === true ? (
//               <div style={{ backgroundColor: "orange", height: "20px" }}>
//                 Best Seller
//               </div>
//             ) : (
//               ""
//             )}
//             <Link to={this.props.link}>
//               <Image
//                 src="https://images-na.ssl-images-amazon.com/images/I/31jBba7+ySL._AC_US160_.jpg"
//                 //  src={this.props.src}
//                 alt="product image"
//                 style={{ maxWidth: "100%" }}
//               />
//             </Link>
//             <Link to="/product-detail">
//               <h2 className="product-title">{this.props.title}</h2>
//             </Link>
//             <div className="brand-div">
//               <span className="brand-name">by {this.props.brand}</span>
//             </div>
//             <div className="ships-to-div">
//               <span className="ships-to">Ships to Pakistan</span>
//             </div>
//           </Card.Body>
//           <Card.Footer>
//             <div className="product-price-div">
//               {/* <span>$</span> */}
//               <span className="product-price">${this.props.price}</span>
//               <span style={{ fontSize: "20px", cursor: "pointer" }}>
//                 <AiOutlineShoppingCart onClick={this.props.addToCart} />
//               </span>
//             </div>
//             <div className="reviews-div">
//               <Rating
//                 name="half-rating-read"
//                 size="small"
//                 defaultValue={this.props.ratingValue}
//                 precision={0.1}
//                 readOnly
//               />
//               <Link to="/reviews" className="total-reviews">
//                 {/* {this.props.NoOfReviews} */}
//                 100,000
//               </Link>
//             </div>
//           </Card.Footer>
//         </Card>
//       </div>
//     );
//   }
// }
function ShopProductCard(props, { id, title, image, price, rating, sellerId }) {
  // const [dispatch] = useStateValue();
  const [like, setLike] = useState(false);
  // const [open, setOpen] = React.useState(false);
  const history = useHistory();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const addLike = () => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined
    )
      return history.push("/login");
    axiosClient()
      .post("/addProductToWishList", { productId: props.id })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    setLike(true);
  };

  const removeLike = () => {
    // props.deleteWishlistProduct(props.wish);
    setLike(false);
    // console.log("false");
  };
  // const addToBasket = () => {
  //   // Dispatch the item into the data layer
  //   dispatch({
  //     type: "ADD_TO_BASKET",
  //     item: {
  //       id: props.id,
  //       title: props.title,
  //       brand: props.brand,
  //       image: props.src,
  //       price: props.price,
  //       rating: props.ratingValue,
  //       quantity: 1,
  //       sellerId: props.userID,
  //     },
  //   });
  // };
  return (
    <>
      <div className="product-card-div">
        <span style={{ display: "none" }}>{props.userID._id}</span>
        <Card>
          <Card.Body className="text-center position-relative">
            {props.stock <= 0 ? (
              <span
                className="badge badge-danger position-absolute"
                style={{ left: "12px", top: "20px" }}
              >
                Out of stock
              </span>
            ) : (
              ""
            )}
            {like === false ? (
              <AiOutlineHeart className="like_icon" onClick={addLike} />
            ) : (
              <AiTwotoneHeart className="like_icon" onClick={removeLike} />
            )}
            {props.bestSeller === true ? (
              <div style={{ backgroundColor: "orange", height: "20px" }}>
                Best Seller
              </div>
            ) : (
              ""
            )}
            <Link to={props.link}>
              <Image
                src={props.src}
                className="shop-card-image"
                alt="product image"
              />
            </Link>
          </Card.Body>
          <Card.Footer>
            <Link to={props.link}>
              <h2 className="product-title">{textTruncate(props.title, 60)}</h2>
            </Link>
            <div className="product-price-div">
              {/* <span>$</span> */}
              <div className="brand-div">
                <span className="brand-name">
                  by <b>{props.brand}</b>
                </span>
              </div>
              {/* <div className="ships-to-div">
                <span className="ships-to"></span>
              </div> */}
              <span className="product-price">PKR {parseInt(props.price)}</span>
              {/* <span style={{ fontSize: "20px", cursor: "pointer" }}>
                <AiOutlineShoppingCart onClick={addToBasket} />
              </span> */}
            </div>
            <div className="reviews-div">
              {/* <Rating
                name="half-rating-read"
                size="small"
                defaultValue={props.ratingValue}
                precision={0.1}
                readOnly
              /> */}
            </div>
          </Card.Footer>
        </Card>
      </div>

      {/* <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"></DialogTitle>
          <DialogContent>
            <Grid item xs={12} md={12}>
              <Link to="/login">
                <Button style={{ backgroundColor: "#F0C14B" }}>
                  Go to Signin page
                </Button>
              </Link>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div> */}
    </>
  );
}

export default ShopProductCard;
