import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import "./Checkout.css";

const deliveryDetails = (props) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" gutterBottom>
          Order Details
        </Typography>
        <TableContainer className="pl-1 pr-1">
          <Table className="my_orders mb-2">
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Product Price</TableCell>
                <TableCell>Delivery Charges</TableCell>
                <TableCell>Tax</TableCell>
                <TableCell>Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.basket.map((b, i) => (
                <TableRow key={i}>
                  <TableCell>{b.title}</TableCell>
                  <TableCell>{`${b.quantity} x ${b.price} = ${Math.round(
                    b.quantity * b.price
                  )}`}</TableCell>
                  <TableCell>{b.shippingCharges}&nbsp;rs/-</TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {props.taxRegion[0]?.tax || 0} %
                  </TableCell>
                  <TableCell>
                    {Math.round(
                      b.quantity * b.price +
                        b.shippingCharges +
                        (parseInt(props.taxRegion[0]?.tax || 0) / 100) *
                          (b.quantity * b.price)
                    )}{" "}
                    rs/-
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mt-4">
        <div className="cal-price-div row">
          {" "}
          <h4 className="col-sm-10">Total Products Price: </h4>
          <span className="col-sm-2">{props.totalProductsPrice}&nbsp;rs/-</span>
        </div>
        <div className="cal-price-div row">
          {" "}
          <h4 className="col-sm-10">Total Shipment Cost: </h4>
          <span className="col-sm-2">{props.totalShipmentCost}&nbsp;rs/-</span>
        </div>
        <div className="cal-price-div row">
          {" "}
          <h4 className="col-sm-10">Total Tax: </h4>
          <span className="col-sm-2">{props.totalTax}&nbsp;rs/-</span>
        </div>
        <hr />
        <div className="cal-price-div row">
          {" "}
          <h4 className="col-sm-10" style={{ fontSize: "14px" }}>
            Total Cost:{" "}
          </h4>
          <span className="col-sm-2">{props.totalCost}&nbsp;rs/-</span>
        </div>
      </Grid>
    </Grid>
  );
};
export default deliveryDetails;
