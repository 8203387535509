import React, { useState, useEffect } from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import "./index.css";
export default function BlogsDetails() {
  return (
    <>
      <Container className="blogs-datails-main-container">
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Carousel>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid"></Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner2}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-2"></Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner3}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-3"></Grid>
            </Carousel.Item>
          </Carousel>
        </div>
        <Grid>
          <Typography className="blogs-details-heading">
            now is your chance to tap into 2022 adsbuyus trends
          </Typography>
          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-text-1">
              Instagram recently released the very first edition of the 2022
              Instagram Trends
              <Typography className="blogs-details-text-1">
                Report—a look ahead to the upcoming next-gen trends defined by
                Gen-Z that will
                <Typography className="blogs-details-text-1">
                  shape culture in the next year. Instagram is where these
                  tastemakers are leading on
                  <Typography className="blogs-details-text-1">
                    the creation of culture, exploring new territories, and
                    taking what already exists in
                    <Typography className="blogs-details-text-1">
                      unexpected directions.
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Typography>
            <Typography className="blogs-details-text-1">
              It is a helpful resource for businesses looking to stay on the
              cutting edge of culture
              <Typography className="blogs-details-text-1">
                on Instagram. Use the trends and insights below to help you
                craft strategy and build
                <Typography className="blogs-details-text-1">
                  content that speaks to your audience in the months to come.
                </Typography>
              </Typography>
            </Typography>
          </Grid>
          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-heading-2">
              tell the story behind your products.
            </Typography>

            <Grid className="blogs-details-main-text">
              <Typography className="blogs-details-text-1">
                Young people are interested in the story behind the
                products—reels that show how
                <Typography className="blogs-details-text-1">
                  the dress was made, a live Q&A to answer questions from
                  potential buyers in real-
                  <Typography className="blogs-details-text-1">
                    time, a quiz in Stories to uncover little-known facts. With
                    the ability to tag products
                    <Typography className="blogs-details-text-1">
                      across every format on Instagram, you can make any type of
                      content an opportunity
                      <Typography className="blogs-details-text-1">
                        to show off your brand’s products.
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid className="blogs-details-img">
          <Grid className="blogs-image-div-2">
            <img src="/images/blogs-details-img-1.jpg" alt="" />
          </Grid>
        </Grid> */}

          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-heading-2">
              Maximalist fashion and minimalist beauty are on the rise.
            </Typography>

            <Grid className="blogs-details-main-text">
              <Typography className="blogs-details-text-1">
                50% of teens and young adults are going to be trying bold
                fashion in 20221, and
                <Typography className="blogs-details-text-1">
                  about one in three young people are interested in learning
                  more about and buying
                  <Typography className="blogs-details-text-1">
                    “clean” makeup / skincare in 20222.
                    <Typography className="blogs-details-text-1">
                      Bold fashion can translate to bold marketing. How might
                      you stop the scroll with
                      <Typography className="blogs-details-text-1">
                        fashion that pushes the envelope and culture forward?
                        How might you use products
                        <Typography className="blogs-details-text-1">
                          like Reels to tell the story behind your product
                          ingredients in a fresh way?
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-heading-2">
              Music is now social.
            </Typography>

            <Grid className="blogs-details-main-text">
              <Typography className="blogs-details-text-1">
                Short-form video is redefining music discovery, and dance
                challenges are here to
                <Typography className="blogs-details-text-1">
                  stay. More than one in three teens are excited to see more
                  dance challenges in 2022.
                  <Typography className="blogs-details-text-1">
                    For a business, this means audio and music are instrumental
                    in kick-starting trends
                    <Typography className="blogs-details-text-1">
                      Bold fashion can translate to bold marketing. How might
                      you stop the scroll with
                      <Typography className="blogs-details-text-1">
                        in short-form video like Reels. How might you consider
                        your brand's audio strategy
                        <Typography className="blogs-details-text-1">
                          and rethink the role music plays in a default sound-on
                          environment?
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid className="blogs-details-img">
          <Grid className="blogs-image-div-2">
            <img src="/images/blogs-details-img-2.jpg" alt="" />
          </Grid>
        </Grid> */}

          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-heading-2">
              Maximalist fashion and minimalist beauty are on the rise.
            </Typography>

            <Grid className="blogs-details-main-text">
              <Typography className="blogs-details-text-1">
                50% of teens and young adults are going to be trying bold
                fashion in 20221, and
                <Typography className="blogs-details-text-1">
                  about one in three young people are interested in learning
                  more about and buying
                  <Typography className="blogs-details-text-1">
                    “clean” makeup / skincare in 20222.
                    <Typography className="blogs-details-text-1">
                      Bold fashion can translate to bold marketing. How might
                      you stop the scroll with
                      <Typography className="blogs-details-text-1">
                        fashion that pushes the envelope and culture forward?
                        How might you use products
                        <Typography className="blogs-details-text-1">
                          like Reels to tell the story behind your product
                          ingredients in a fresh way?
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <Grid className="blogs-details-main-text">
            <Typography className="blogs-details-heading-2">
              Music is now social.
            </Typography>

            <Grid className="blogs-details-main-text">
              <Typography className="blogs-details-text-1">
                Short-form video is redefining music discovery, and dance
                challenges are here to
                <Typography className="blogs-details-text-1">
                  stay. More than one in three teens are excited to see more
                  dance challenges in 2022.
                  <Typography className="blogs-details-text-1">
                    For a business, this means audio and music are instrumental
                    in kick-starting trends
                    <Typography className="blogs-details-text-1">
                      Bold fashion can translate to bold marketing. How might
                      you stop the scroll with
                      <Typography className="blogs-details-text-1">
                        in short-form video like Reels. How might you consider
                        your brand's audio strategy
                        <Typography className="blogs-details-text-1">
                          and rethink the role music plays in a default sound-on
                          environment?
                        </Typography>
                      </Typography>
                    </Typography>
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid className="blogs-details-img">
          <Grid className="blogs-image-div-2">
            <img src="/images/blogs-details-img-3.jpg" alt="" />
          </Grid>
        </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
