import React, { Component } from "react";
import "./SearchProductCard.css";
import { Card, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiTwotoneDelete } from "react-icons/ai";
import { textTruncate } from "../../helper";
// import Rating from "@material-ui/lab/Rating";

class SearchProductCard extends Component {
  render() {
    return (
      <div className="search-card-div">
        <Card>
          <Card.Body>
            <Row>
              <Col lg={3} md={12} sm={12}>
                <Link to={this.props.link}>
                  <Image
                    src={this.props.src}
                    alt={this.props.alt}
                    className="search-card-image"
                  />
                </Link>
              </Col>
              <Col lg={7} md={10} sm={10} xs={10} className="column-text">
                {this.props.isSponsored === true ? (
                  <div style={{ backgroundColor: "orange", height: "20px" }}>
                    Sponsored
                  </div>
                ) : (
                  ""
                )}
                <Link to={this.props.link}>
                  <h2 className="search-title text-capitalize">
                    {textTruncate(this.props.title, 60)}
                  </h2>
                </Link>
                {/* <div className="search-reviews-div">
                  <Rating
                    name="half-rating-read"
                    size="small"
                    defaultValue={this.props.ratingValue}
                    precision={0.1}
                    readOnly
                  />
                  <Link to="/reviews" className="search-total-reviews">
                    {this.props.NoOfReviews}
                  </Link>
                </div> */}
                <Link>
                  <span className="search-brand-name">
                    by {this.props.brand}
                  </span>
                </Link>
                <div className="search-ships-div">
                  <span className="search-ships-to">Ships to Pakistan</span>
                </div>
                <div className="search-price-div">
                  <span className="search-price">Rs.{this.props.price}</span>
                </div>
              </Col>
              {this.props.showDelete === true ? (
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="delete-div text-center"
                >
                  <AiTwotoneDelete
                    onClick={() =>
                      this.props.deleteWishlistProduct(this.props.wishlist)
                    }
                    className="delete-icon"
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
export default SearchProductCard;
