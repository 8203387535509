import React, { useEffect } from "react";
import "./App.css";
import Header from "./Header";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import SubcategoryShop from "./pages/subCategoryShop/SubcategoryShop";
import SearchResults from "./pages/searchResults/SearchResults";
import SearchNotFound from "./pages/searchNotFound/searchNotFound";
import ProductDetail from "./pages/ProductDetails/Products";
import WishList from "./pages/wishList/wishList";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Checkout from "./Checkout";
import Login from "./Login";
import Signup from "./Signup";
import IWant from "./pages/iWant/IWant";
import Orders from "./Orders";
import Footer from "../src/components/Footer/Footer";
import BlogDetails from "./pages/blogs-Details";
import CheckOut from "./pages/Checkout/checkout";
import whatsapp from "./assets/whatsapp.png";
import ManageProfile from "./pages/ManageProfile/ManageProfile";
import MyOrders from "./pages/myOrders/MyOrders";
import ReturnRequest from "./pages/myOrders/ReturnRequest";
import ViewReturnRequests from "./pages/myOrders/ViewReturnRequests";
import EmailVerify from "./pages/forgotPassword/emailVerify";
import VerifyCode from "./pages/forgotPassword/verifyCode";
import ResetPassword from "./pages/forgotPassword/resetPassword";
import InvoiceViewer from "./pages/invoice/invoice";
import InvoiceTable from "./pages/invoice/invoiceList";
import IwantRequests from "./pages/iWant/viewiWant/viewiWant";
import Blogs from "./pages/blogs/blogs";
import FeaturedProductCard from "./components/FeaturedProductsCard/index";
const buyerId = localStorage.getItem("userId");

function App() {
  useEffect(() => {
    let expiry = localStorage.getItem("tokenExpiry");
    let currentDate = new Date();
    if (currentDate.getTime() > expiry) {
      localStorage.clear();
    }
  }, []);
  return (
    <>
      {/* // BEM */}
      <Router>
        <Switch>
          <Route exact path={`/my-invoices/${buyerId}`}>
            <Header />
            <InvoiceTable />
            <Footer />
          </Route>
          <Route exact path={`/invoice-details/:id`}>
            <Header />
            <InvoiceViewer />
            <Footer />
          </Route>
          <Route exact path="/">
            {/* <Header /> */}
            <Home />
            {/* <Footer /> */}
          </Route>
          <Route path="/reset-password/:code?" component={ResetPassword} />
          <Route path="/verify-code" component={VerifyCode}></Route>
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/email-verify" component={EmailVerify} />

          {/* <Route path="/emailverify ">
            <EmailVerify />
          </Route> */}

          <Route path="/shop/:id/:pageNumber/:location">
            <Header />
            <Shop />
            <Footer />
          </Route>
          <Route path="/subshop/:catId/:id/:pageNumber/:location">
            <Header />
            <SubcategoryShop />
            <Footer />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/checkout">
            <Header />
            <Checkout />
            <Footer />
          </Route>
          <Route path="/orders">
            <Header />
            <Orders />
            <Footer />
          </Route>
          <Route exact path="/iwant">
            <Header />
            <IWant />
            <Footer />
          </Route>

          <Route exact path="/iwant-requests">
            <Header />
            <IwantRequests />
            <Footer />
          </Route>

          <Route path="/searchresults/:categoryId/:productName">
            <Header />
            <SearchResults />
            <Footer />
          </Route>
          <Route path="/searchnotfound">
            <Header />
            <SearchNotFound />
            <Footer />
          </Route>
          <Route path="/product-detail/:productId">
            <Header />
            <ProductDetail />
            <Footer />
          </Route>
          <Route path="/check-out">
            <CheckOut />
            <Footer />
          </Route>
          <Route path="/wishList">
            <Header />
            <WishList />
            <Footer />
          </Route>
          <Route path="/manage-profile">
            <Header />
            <ManageProfile />
            <Footer />
          </Route>
          <Route path="/myorders">
            <Header />
            <MyOrders />
            <Footer />
          </Route>
          <Route path="/return-request/:id/:productId">
            <Header />
            <ReturnRequest />
            <Footer />
          </Route>
          <Route path="/return-requests">
            <Header />
            <ViewReturnRequests />
            <Footer />
          </Route>
          <Route path="/FeaturedProductCard">
            <FeaturedProductCard />
          </Route>

          <Route path="/Blogs">
            <Blogs />
          </Route>

          <Route path="/blogdetails">
            <BlogDetails />
          </Route>
        </Switch>
      </Router>
      <a href="https://wa.me/923454007766?text=Hi, can you check the availability of product?">
        <img src={whatsapp} alt="whatsapp-icon" className="whatsapp_icon" />
      </a>
    </>
  );
}

export default App;
