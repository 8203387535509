import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, useParams, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import { useStateValue } from "../../StateProvider";
import "../Shop/Shop.css";
import ShopFilterSection from "../../components/ShopFilterSection/ShopFilterSection";
import ShopProductCard from "../../components/ShopProductCard/ShopProductCard";
import { axiosClient } from "../../helper";
import PaginationControlled from "../Shop/pagination";

let newnameBrand = [];
let newnameLocation = [];
let newnameSubCategories = [];

const SubcategoryShop = () => {
  const params = useParams();
  let history = useHistory();
  const [{ choosedLocation }] = useStateValue();
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("Computers");
  // const [products, setProducts] = useState([]);
  // const [totalProducts, setTotalProducts] = useState(0);
  // const [cartProducts, setCartProducts] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  const [subCategory, setSubCategory] = useState([]);
  const [filters, setFilters] = useState({ featuredbrands: [] });
  const [isAll, setIsAll] = useState(false);
  const [filterBrands, setFilterBrands] = useState([]);
  const [sellerId, setSellerId] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subProducts, setSubProducts] = useState([]);
  const [brand, setBrand] = useState([]);
  const [singleSubcategory, setSingleSubcategory] = useState("");
  const [activeItemIndex, setActiveItemIndex] = useState("");
  const [loader, setLoader] = useState(true);

  const [brandNames, setBrandNames] = useState([]);
  const [locationNames, setLocationNames] = useState([]);

  const [checkedBrand, setCheckedBrand] = useState([]);
  const [checkednameBrand, setCheckednameBrand] = useState([]);

  const [checkedLocation, setCheckedLocation] = useState([]);
  const [checkednameLocation, setCheckednameLocation] = useState([]);

  const [checkedSubCategories, setCheckedSubCategories] = useState([]);
  const [checkednameSubCategories, setCheckednameSubCategories] = useState([]);

  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  const [subCategoriesName, setSubCategoriesName] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [productsLength, setProductsLength] = useState(0);

  useEffect(() => {
    let id = params.id;
    setSubCategoryId(id);
    getProductsBySubcategoryId(id);
    getSingleSubcategory(id);
  }, [params.id, choosedLocation, pageNumber, params.location]);

  useEffect(() => {
    history.push(
      `/subshop/${params.catId}/${params.id}/${pageNumber}/${choosedLocation}`
    );
  }, [choosedLocation]);

  const setMinMaxPrice = (minPrice, maxPrice) => {
    setMinPrice(minPrice);
    setMaxPrice(maxPrice);
    handleFilters(brandNames, locationNames, minPrice, maxPrice);
  };

  const handleToggleBrand = (value, name, e) => {
    const currentIndexBrand = checkedBrand.indexOf(value);
    const newCheckedBrand = [...checkedBrand];

    if (currentIndexBrand === -1) {
      newCheckedBrand.push(value);
      newnameBrand.push(e.target.value);
    } else {
      newCheckedBrand.splice(currentIndexBrand, 1);
      newnameBrand.splice(currentIndexBrand, 1);
    }
    setCheckedBrand(newCheckedBrand);
    setBrandNames(newnameBrand);
    handleFilters(
      newnameBrand,
      subCategoriesName,
      locationNames,
      minPrice,
      maxPrice
    );
  };

  const handleToggleLocation = (value, name, e) => {
    const currentIndexLocation = checkedLocation.indexOf(value);
    const newCheckedLocation = [...checkedLocation];

    if (currentIndexLocation === -1) {
      newCheckedLocation.push(value);
      newnameLocation.push(e.target.value);
    } else {
      newCheckedLocation.splice(currentIndexLocation, 1);
      newnameLocation.splice(currentIndexLocation, 1);
    }
    setCheckedLocation(newCheckedLocation);
    setLocationNames(newnameLocation);
    handleFilters(brandNames, newnameLocation, minPrice, maxPrice);
  };

  const getSingleSubcategory = (id) => {
    axiosClient()
      .get(`/getSubCategoryById/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setCategoryId(response.data.subCategory.categoryId);
          setSingleSubcategory(response.data.subCategory);
          getSubcategoryByCategoryId(response.data.subCategory.categoryId._id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubcategoryByCategoryId = (id) => {
    axiosClient()
      .get(`/getSubCategoriesByCategoryId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSubCategory(response.data.subCategory);
        }
      })
      .catch((error) => {
        console.log("in subcategory shop: ", error);
      });
  };

  const getProductsBySubcategoryId = (id) => {
    axiosClient()
      .get(
        `/admin/getProductsBySubcategoryId/${id}/${pageNumber}/${choosedLocation}`
      )
      .then((response) => {
        setLoader(false);
        if (response.status === 200) {
          setSubProducts(response.data.products);
          setProductsLength(response.data.length);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleFilters = (
    brand,
    locationByCity,
    subCategoryId,
    minPrice,
    maxPrice
  ) => {
    if (locationByCity === undefined) locationByCity = [];
    if (
      brand.length < 1 &&
      locationByCity.length < 1 &&
      !minPrice &&
      !maxPrice
    ) {
      return getProductsBySubcategoryId(params.id);
    }
    axiosClient()
      .post(`/getProductsByFilter`, {
        brand: brand,
        categoryId: [params.catId],
        subCategoryId: [params.id],
        locationByCity: locationByCity,
        minPrice: Number(minPrice),
        maxPrice: Number(maxPrice),
      })
      .then((response) => {
        if (response.status === 200) {
          setSubProducts(response.data.productsByBrand);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubCategoryId = (subCatId) => {
    setActiveItemIndex(subCatId);
    axiosClient()
      .get(
        `/admin/getProductsBySubCategoryId/${subCatId}/${pageNumber}/${choosedLocation}`
      )
      .then((res) => {
        setSubProducts(res.data.products);
        setProductsLength(res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleSubCategories = (value, name, e) => {
    const currentIndexSubCategories = checkedSubCategories.indexOf(value);
    const newCheckedSubCategories = [...checkedSubCategories];

    if (currentIndexSubCategories === -1) {
      newCheckedSubCategories.push(value);
      newnameSubCategories.push(e.target.value);
    } else {
      newCheckedSubCategories.splice(currentIndexSubCategories, 1);
      newnameSubCategories.splice(currentIndexSubCategories, 1);
    }
    setCheckedSubCategories(newCheckedSubCategories);
    // this.setState({ checkednameSubCategories: newnameSubCategories });
    setSubCategoriesName(newnameSubCategories);
    handleFilters(
      brandNames,
      newnameSubCategories,
      locationNames,
      minPrice,
      maxPrice
    );
  };

  const calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (price / 100) * commissionAmount + price;
    } else if (isFixed) {
      return price + commissionAmount;
    } else {
      return price;
    }
  };

  const handlePagination = (event, value) => {
    setPageNumber(value);
    history.push(
      `/subshop/${params.catId}/${params.id}/${value}/${choosedLocation}`
    );
  };

  return (
    <>
      <Helmet>
        <title>Shop</title>
        <meta
          name="Spada Traders shop page"
          content="Spada Traders Shop Page"
        />
      </Helmet>
      <Container>
        <Row className="shop_columns">
          <Col lg={2} md={12}>
            <ShopFilterSection
              handleFilters={(filters) =>
                handleFilters(filters, "featuredbrands")
              }
              subCategory={subCategory}
              getSubcategory={(id) => getSubcategoryByCategoryId(id)}
              handleSubCategoryId={handleSubCategoryId}
              brand={brand}
              activeItemIndex={activeItemIndex}
              handleToggleBrand={handleToggleBrand}
              checkedBrand={checkedBrand}
              handleToggleLocation={handleToggleLocation}
              checkedLocation={checkedLocation}
              setMinMaxPrice={setMinMaxPrice}
              handleToggleSubCategories={handleToggleSubCategories}
              checkedSubCategories={checkedSubCategories}
            />
          </Col>
          <Col
            lg={10}
            md={12}
            sm={12}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div style={{ marginBottom: "1rem" }}>
              <h1 className="top-heading">
                <b>{singleSubcategory.subCategoryName}</b>
              </h1>
              <p className="top-para">
                Shop{" "}
                {subCategory
                  .slice(0, 3)
                  .map(
                    (subcat) => subcat.subCategoryName.toLowerCase() + ","
                  )}{" "}
                and more
              </p>
            </div>
            <hr />
            {loader ? (
              <div className="loaders">
                <CircularProgress />
              </div>
            ) : (
              <Row
                style={{
                  marginTop: "24px",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                {subProducts?.length > 0 ? (
                  subProducts.map((productdata) => (
                    <Col md={4} sm={6} sm={12}>
                      <ShopProductCard
                        id={productdata._id}
                        src={productdata.image[0]}
                        alt={productdata.name}
                        title={productdata.name}
                        brand={productdata.brand}
                        stock={productdata.countInStock}
                        price={calculatePrice(
                          productdata.isPercentage,
                          productdata.isFixed,
                          productdata.price,
                          parseInt(productdata.commissionAmount)
                        )}
                        ratingValue={productdata.averageRating}
                        link={`/product-detail/${productdata._id}`}
                        userID={productdata.userID}
                      />
                    </Col>
                  ))
                ) : (
                  <Col md={4} sm={6}>
                    <h4>No Product found</h4>
                  </Col>
                )}
                <Col md={12} sm={12} lg={12}>
                  <PaginationControlled
                    pageNumber={pageNumber}
                    productsLength={Math.ceil(productsLength / 9)}
                    handlePagination={handlePagination}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default withRouter(SubcategoryShop);
