import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CheckoutForm from "./checkoutForm";
import FirstCard from "./FirstCard";
import DeliveryDetails from "./deliveryDetails";

import { useStateValue } from "../../StateProvider";
import { getBasketTotal } from "../../reducer";
import { axiosClient } from "../../helper";
import deosaiLogo from "../../assets/desosaiLogo.png";
import SpadaSoftLogo from "../../assets/logos/spada-traders-latest.png";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    margin: "auto",
    boxShadow: "0px 0px 8px #dbdbdb !important",
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 670,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  signuplogo: {
    width: "150px",
  },
  paper: {
    // marginTop: theme.spacing(3),
    marginTop: "16px",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    // padding: theme.spacing(3, 0, 5),
    background: "transparent",
    paddingTop: "12px",
    marginTop: "16px!important",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: "75%",
      margin: "auto",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "#3EB986",
    "&:hover": {
      backgroundColor: "#3EB986",
    },
    color: "black",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ["Shipping address", "Order details", "Payment details"];

const Checkout = () => {
  const history = useHistory();
  const [
    {
      basket,
      totalShipping,
      choosedLocation,
      selectedCityCode,
      selectedCityRegion,
    },
    dispatch,
  ] = useStateValue();

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const [loader, setLoader] = useState(true);
  const [success, setSuccess] = useState();

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("Pakistan");

  const [zipCode, setZipCode] = useState("");

  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [expiry, setExpiry] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [creditExp, setCreditExp] = useState("");
  const [allTaxes, setAllTaxes] = useState([]);
  const [taxRegion, setTaxRegion] = useState("");
  const [regionNames, setRegionNames] = useState([]);
  const [cities, setCities] = useState([]);

  const [cityCode, setCityCode] = useState(selectedCityCode);
  const [city, setCity] = useState(
    choosedLocation !== "All" ? choosedLocation : ""
  );
  // const [state, setState] = useState(
  //   choosedLocation === "All"
  //     ? choosedLocation
  //     : selectedCityRegion.provinceName
  // );
  const [state, setState] = useState(
    selectedCityRegion.provinceName
      ? selectedCityRegion.provinceName
      : selectedCityRegion
  );

  const [totalTax, setTotalTax] = useState(0);
  // const [validated, setValidated] = useState(true);

  const [isRememberAddres, setIsRememberAddres] = useState(false);
  const handleChange = (event) => {
    setIsRememberAddres(event.target.checked);
  };

  useEffect(() => {
    if (basket.length <= 0) {
      history.push("/checkout");
      toast.info("Your cart is empty. Please add items to proceed");
    }
    getCurrentLocation();
    getBuyerSavedAddress();
    getAllTaxes("");
    // if (choosedLocation === "All") {
    //   getAllProvinces();
    // }
  }, []);

  useEffect(() => {
    getAllTaxes("");
    if (choosedLocation === "All") {
      getCurrentLocation();
      // getAllProvinces();
    }
  }, [state]);

  const getAllProvinces = (currentProvince, currentCity) => {
    // let newRegionArr = [];
    axiosClient()
      .get(`/getAllProvince`)
      .then((response) => {
        if (choosedLocation === "All") {
          let currentState = currentProvince
            ? currentProvince
            : state
            ? state
            : "";
          let a = response.data.data.filter(
            (m) => m.provinceName.toLowerCase() === currentState.toLowerCase()
          );
          a.length > 0 && getCitiesByProvinceId(a[0]._id, currentCity);
        }
        // response.data.data.map((m) => {
        //   newRegionArr.push({ id: m._id, title: m.provinceName });
        // });
        // setRegionNames(newRegionArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCitiesByProvinceId = (id, currentCity) => {
    // let newCitiesArr = [];
    axiosClient()
      .get(`/getCitiesByProvinceId/${id}`)
      .then((response) => {
        let a = response.data.data.filter(
          (x) => x.cityName.toLowerCase() === currentCity.toLowerCase()
        );
        setCityCode(a[0].cityCode);
        // response.data.data.map((c) => {
        //   newCitiesArr.push({ title: c.cityName, code: c.cityCode });
        // });
        // setCities(newCitiesArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentLocation = () => {
    axios
      .get("https://geolocation-db.com/json/geoip.php?json=callback")
      .then((response) => {
        // console.log("city response: ", choosedLocation, state);
        if (choosedLocation === "All") {
          setState(response.data.state ? response.data.state : "");
          setCity(response.data?.city ? response.data?.city : "");
          getAllProvinces(
            response.data?.state ? response.data?.state : "",
            response.data?.city ? response.data?.city : ""
          );
          getAllTaxes(response.data?.state ? response.data?.state : "");
        }
      });
  };

  const getBuyerSavedAddress = () => {
    axiosClient()
      .get(`/getBuyerSavedAddress`)
      .then((response) => {
        setFullName(response.data.data?.rememberFullName);
        setPhone(response.data.data?.rememberPhone);
        setAddress(response.data.data?.rememberAddress);
        setZipCode(response.data.data?.rememberZipCpde);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllTaxes = (province) => {
    axiosClient()
      .get(`/getAllTaxes`)
      .then((res) => {
        let currentState = province ? province : state;
        setAllTaxes(res.data.data);
        const a = res.data.data?.filter(
          (at) => at.regionName.toLowerCase() === currentState.toLowerCase()
        );
        setTaxRegion(a);
        calTaxPrice(a);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeState = (event, value) => {
    setState(value.title);
    getCitiesByProvinceId(value.id);
    const a = allTaxes.filter((at) => at.regionName === value.title);
    setTaxRegion(a);
    calTaxPrice(a);
  };

  const onChangeCity = (event, value) => {
    setCity(value.title);
    setCityCode(value.code);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <CheckoutForm
            isRememberAddres={isRememberAddres}
            handleChange={handleChange}
            fullName={fullName}
            setFullName={setFullName}
            phone={phone}
            setPhone={setPhone}
            address={address}
            setAddress={setAddress}
            country={country}
            setCountry={setCountry}
            city={city}
            onChangeCity={onChangeCity}
            state={state}
            onChangeState={onChangeState}
            zipCode={zipCode}
            setZipCode={setZipCode}
            cities={cities}
            regionNames={regionNames}
          />
        );
      case 1:
        return (
          <DeliveryDetails
            basket={basket}
            taxRegion={taxRegion}
            totalTax={totalTax}
            totalProductsPrice={getBasketTotal(basket)}
            totalShipmentCost={totalShipping}
            totalCost={getBasketTotal(basket) + totalShipping + totalTax}
          />
        );

      case 2:
        return (
          <FirstCard
            // validated={validated}
            creditExp={creditExp}
            onChangePaymentMethod={onChangePaymentMethod}
            cardNumber={cardNumber}
            onChangeCardNumber={onChangeCardNumber}
            nameOnCard={nameOnCard}
            onChangeNameOnCard={onChangeNameOnCard}
            cvc={cvc}
            onChangeCvc={onChangeCvc}
            expiry={expiry}
            onChangeExpiry={onChangeExpiry}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  const onChangeCardNumber = (e) => {
    setCardNumber(e.target.value);
  };
  const onChangeNameOnCard = (e) => {
    setNameOnCard(e.target.value);
  };
  const onChangeCvc = (e) => {
    setCvc(e.target.value);
  };
  const onChangeExpiry = (e) => {
    setExpiry(e.target.value);
    let exp = e.target.value.split("/");
    let month = exp[0];
    let year = exp[1];
    setExpiryMonth(month);
    setExpiryYear(year);
  };
  const onChangePaymentMethod = (e) => {
    if (e.target.value === "Cash") {
      setCreditExp("Cash");
    } else if (e.target.value === "Card") {
      setCreditExp("Card");
    }
  };
  const handleNext = () => {
    if (!fullName || !address || !country || !state || !city || !phone) {
      return toast.error("Please fill all required fields to proceed");
    } else if (phone.replace(/-|_/g, "").length < 15) {
      return toast.error("Phone number is not valid");
    }
    if (activeStep === 2) {
      if (!creditExp) {
        return toast.error("Please choose a payment method to proceed");
      } else if (creditExp === "Card") {
        if (!cardNumber || !nameOnCard || !expiry || !cvc) {
          return toast.error("Please add complete card details to place order");
        } else {
          handlePlaceOrder();
        }
      } else if (creditExp === "Cash") {
        handlePlaceOrder();
      }
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const calTaxPrice = (region) => {
    var totaltax = 0;

    if (region[0]?.tax >= 0) {
      basket.map((b, i) => {
        totaltax +=
          (parseFloat(region[0]?.tax).toFixed(2) / 100) *
          (b.quantity * b.price);
      });
    } else {
      totaltax = 0;
    }
    setTotalTax(totaltax);
  };

  const handlePlaceOrder = (event) => {
    setLoader(false);

    var orderItems = [];
    // var totalTax = 0;

    basket.map((b, i) => {
      var currentTime = new Date();
      var updatedTime;
      if (b.estimatedDeliveryTime.deliveryTimeUnit === "Hour") {
        currentTime.setHours(
          currentTime.getHours() +
            parseInt(b.estimatedDeliveryTime?.deliveryTime)
        );
        updatedTime = currentTime;
      } else if (b.estimatedDeliveryTime.deliveryTimeUnit === "Day") {
        currentTime.setHours(
          currentTime.getHours() +
            parseInt(b.estimatedDeliveryTime?.deliveryTime * 24)
        );
        updatedTime = currentTime;
      } else if (b.estimatedDeliveryTime.deliveryTimeUnit === "Week") {
        currentTime.setHours(
          currentTime.getHours() +
            parseInt(b.estimatedDeliveryTime?.deliveryTime * 7 * 24)
        );
        updatedTime = currentTime;
      }

      const deliverCity = cities.filter((c) =>
        b.deliveryTimeline.some((d) => d.deliveryLocation === c.title)
      );
      deliverCity.map((dc) =>
        console.log(
          "deliverableCity: ",
          dc.title,
          dc.title.includes(city.title)
        )
      );

      // totalTax += (parseInt(taxRegion[0]?.tax) / 100) * (b.quantity * b.price);

      orderItems.push({
        productId: b.id,
        productName: b.title,
        quantity: b.quantity,
        sellerId: b.sellerId,
        // price: b.price,
        unitPrice: Math.round(b.price),
        tax: (parseInt(taxRegion[0]?.tax || 0) / 100) * b.price * b.quantity,
        productShippingCharges: Math.round(b.shippingCharges),
        price: Math.round(
          b.quantity * b.price +
            b.shippingCharges +
            (parseInt(taxRegion[0]?.tax || 0) / 100) * (b.quantity * b.price)
        ),
        category: b.category,
        adminCommissionAmount: b.adminCommissionAmount,
        estimatedDeliveryTime: updatedTime,
      });
    });

    console.log("orderItems: ", orderItems);

    const data = {
      isRemeberAddress: isRememberAddres,
      orderItems: orderItems,
      customerName: fullName,
      phone: phone,
      shippingAddress: address,
      country: country,
      cityName: city,
      cityCode: cityCode,
      state: state,
      zipCode: zipCode,
      paymentMethod: creditExp,
      cardNumber: cardNumber,
      nameOnCard: nameOnCard,
      cvc: cvc,
      cardExpiryMonth: expiryMonth,
      cardExpiryYear: expiryYear,
      // expiry: expiry,
      totalPrice: getBasketTotal(basket) + totalShipping + totalTax,
      userID: localStorage.getItem("userId"),
    };

    // if (creditExp === "Card") {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    //   setValidated({ validated: true });
    // }
    axiosClient()
      .post("/createOrder", data)
      .then((res) => {
        dispatch({
          type: "EMPTY_BASKET",
        });
        setSuccess(true);
        setLoader(true);
      })
      .catch((error) => {
        setLoader(true);
        setSuccess(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="main_stepper_form">
      <CssBaseline />
      <ToastContainer />
      <Stepper activeStep={activeStep} className={classes.stepper}>
        <Link to="/">
          <img className="payment_form_logo" src={SpadaSoftLogo} />
        </Link>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Link to="/">
            <img
              className="payment_form_logo_mbl"
              src={SpadaSoftLogo}
              alt="deosai"
            />
          </Link>

          <React.Fragment>
            {activeStep === steps.length ? (
              loader ? (
                success === true ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Thank you for your order.
                    </Typography>
                    <Typography variant="subtitle1">
                      Your order is created successfully.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push("/")}
                      className={classes.button}
                    >
                      Continue Shopping
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Order not created
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => history.push("/")}
                      className={classes.button}
                    >
                      Back to Home page
                    </Button>
                  </React.Fragment>
                )
              ) : (
                <LinearProgress />
              )
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    style={{ fontWeight: "bold" }}
                  >
                    {activeStep === steps.length - 1
                      ? "Place order"
                      : "Continue"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </div>
  );
};
export default Checkout;
