import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useHistory } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useStateValue } from "../../StateProvider";
import { axiosClient } from "../../helper";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function Navbar() {
  const classes = useStyles();
  const [{ choosedLocation }] = useStateValue();
  const [state, setState] = useState({ left: false });
  const [category, setCategory] = useState([]);
  const [subCategory, setSubcategory] = useState([]);
  const [catIdForFilter, setCatIdForFilter] = useState();
  const [menuName, setMenuName] = React.useState(null);

  let history = useHistory();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    axiosClient()
      .get("/getCategories")
      .then((response) => {
        if (response.status === 200) {
          setCategory(response.data.categories);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const getSubcategoryByCategoryId = (id) => {
    axiosClient()
      .get(`/getSubCategoriesByCategoryId/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSubcategory(response.data.subCategory);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => {
    let arr = menuName !== null ? subCategory : category;
    const clickListener = (id) => {
      setCatIdForFilter(id);
      getSubcategoryByCategoryId(id);
      if (!menuName) {
        return setMenuName(id);
      } else {
        return (
          toggleDrawer(anchor, true),
          history.push(`/subshop/${catIdForFilter}/${id}/1/${choosedLocation}`)
        );
        // return alert(`${id} clicked`);
        // return <Link to={`/subshop/${catIdForFilter}/${id}`}></Link>;
        // return (window.location = `/subshop/${catIdForFilter}/${id}`);
        // return (window.location = `/subshop/${catIdForFilter}/${id}`);
        // return setMenuName(null);
      }
    };
    return (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {arr?.map((text, index) => (
            <ListItem
              button
              key={text._id}
              onClick={() => clickListener(text._id)}
            >
              <ListItemText
                primary={menuName === null ? text.name : text.subCategoryName}
              />
              {!menuName && <ChevronRightIcon />}
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div>
      {["left"]?.map((anchor) => (
        <React.Fragment key={anchor}>
          <FaBars
            className="fa_bars"
            onClick={toggleDrawer(anchor, true)}
            style={{ verticalAlign: "inherit", cursor: "pointer" }}
          />
          {/* <span>All</span> */}
          {/* {anchor}</FaIcons.FaBars> */}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {menuName && (
              <ListItem button onClick={() => setMenuName(null)}>
                <ListItemText primary="Back to Categories" />
                <ChevronLeftIcon />
              </ListItem>
            )}
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
