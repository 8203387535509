import React from "react";
import "./Footer.css";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/logos/web-logo.jpg";
// import { useNavigate } from "react-router-dom";

import SpadaLogo from "../../assets/logos/st.png";
function Footer() {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="Footer">
      <a onClick={handleClick}>
        <div className="Footer__Back-To-Top">
          <ExpandLessIcon className="Footer__Back-To-Top-Text" />
          {/* <span className="Footer__Back-To-Top-Text"y/> */}
        </div>
      </a>

      <Grid container justify="center">
        <Grid item xs={6} sm={4} md={2} lg={2}>
          {/* <div className="Footer__Vertical-Row">*/}
          <div className="Footer__Vertical-Col">
            <div className="Footer__Vertical-Col-Head">Get to Know Us</div>
            <ul>
              <li>About</li>
              <li>Career</li>
              <li>Press</li>
              <li>Spada Traders Cares</li>
              <li>Gift a smile</li>
              <a href="/Blogs">
                <li className="blogs-text">Blogs</li>
              </a>
            </ul>
          </div>
        </Grid>
        {/* <Grid item xs={5} sm={3} md={2} lg={2}>
          <div className="Footer__Vertical-Col">
            <div className="Footer__Vertical-Col-Head">Connect with us</div>
            <ul className="footer_ul">
              <li>Facebook</li>
              <li>Twitter</li>
              <li>Instagram</li>
            </ul>
          </div>
        </Grid> */}
        <Grid item xs={6} sm={4} md={2} lg={2}>
          <div className="Footer__Vertical-Col">
            <div className="Footer__Vertical-Col-Head">Make Money with Us</div>
            <ul>
              <li>Sell on Spada Traders</li>
              <li>Sell under Spada Traders</li>
              <li>Become an Affiliate</li>
              <li>Fulfilment by Spada Traders</li>
              <li>Spada Traders Pay</li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={6} sm={4} md={2} lg={2}>
          <div className="Footer__Vertical-Col">
            <div className="Footer__Vertical-Col-Head">Connect with us</div>
            <ul>
              <li>COVID-19 and Spada Traders</li>
              <li>Your Account</li>
              <li>Returns Centre</li>
              <li>100% Purchase Protection</li>
              <li>Help</li>
            </ul>
          </div>
        </Grid>
      </Grid>
      {/* </div> */}

      <hr style={{ marginTop: "0.5%", border: "none" }} />

      <div className="Footer__Line">
        <img className="Footer__Line-logo" src={SpadaLogo} alt="" />
        <span className="Footer__Line-message">
          Spada Traders
          <a target="_blank" href={"http://spadasoft.com/"}>
            developed by &copy; spadasoft
          </a>
          {/* </a> */}
        </span>
      </div>
    </div>
  );
}

export default Footer;
