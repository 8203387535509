import React from "react";
import "./ProductDetails.css";
import { makeStyles } from "@material-ui/core/styles";
// import Rating from "@material-ui/lab/Rating";
import Paper from "@material-ui/core/Paper";
// import ReactStars from "react-rating-stars-component";
// import { MDBBadge, MDBContainer } from "mdbreact";
// import { Button } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(1),
    textAlign: "justify",
    color: "black",
    lineHeight: 0.8,
  },
  heading: {
    fontSize: "24px !important",
    lineHeight: "32px !important",
    textTransform: "capitalize",
    textAlign: "initial",
    wordSpacing: "2px",
  },
  info: {
    fontSize: "14px !important",
    lineHeight: "20px !important",
  },
}));

const ProductDetail = (props) => {
  const classes = useStyles();
  let item = [props.item];
  return (
    <>
      {item.map((items, k) => {
        return (
          <Paper className={classes.paper} key={k}>
            <h1 className={classes.heading}>{items.name}</h1>
            {items.countInStock <= 0 ? (
              <span className="badge badge-danger">Out of stock</span>
            ) : (
              ""
            )}
            <hr />
            <TableContainer component={Paper}>
              <Table
                className="top-table"
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell className="left_cells">Price</TableCell>
                    <TableCell align="left">PKR {props.updatedPrice}</TableCell>
                  </TableRow>
                  {items.color ? (
                    <TableRow>
                      <TableCell align="left" className="left_cells">
                        Color
                      </TableCell>
                      <TableCell align="left">{items.color}</TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                  {items.brand ? (
                    <TableRow>
                      <TableCell align="left" className="left_cells">
                        Brand
                      </TableCell>
                      <TableCell align="left">{items.brand}</TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <hr />
            <br />
            {items.description ? (
              <div>
                <h1 className="about">About this item </h1>
                {/* <span className="description">{items.description}</span> */}
                <span
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: items.description,
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </Paper>
        );
      })}
    </>
  );
};

export default ProductDetail;
