import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";
import { ToastContainer, toast } from "react-toastify";

function CheckoutProduct({
  id,
  title,
  image,
  price,
  // rating,
  hideButton,
  quantity,
  minimumQuantity,
  shippingCharges,
  isMultipleAllow,
  maximumQuantity,
  chooseMultiple,
  initialShippingCharges,
  maxTransportationVolume,
  // productId,
  array,
}) {
  const [{ basket }, dispatch] = useStateValue();
  const [qty, setQty] = useState(quantity);
  const [minimumQty, setMinimumQty] = useState(minimumQuantity);

  const removeFromBasket = () => {
    // Remove the item from the basket
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    });

    dispatch({
      type: "REMOVE_SHIPPING",
      item: {
        shippingCharges: shippingCharges,
      },
    });
  };

  const handleQuantityUpdate = (e) => {
    setQty(parseInt(e.target.value));
  };

  const calCharges = (quantity) => {
    let changedQty = quantity;
    let charges = 0;
    if (maxTransportationVolume > 0) {
      let quotient = Math.floor(changedQty / maxTransportationVolume);
      let remainder = changedQty % maxTransportationVolume;
      console.log("quantity: ", quantity, quotient, remainder);
      if (quotient === 0) {
        charges = initialShippingCharges;
      } else if (quotient === 1 && remainder === 0) {
        charges = initialShippingCharges;
      } else if (quotient > 1 && remainder === 0) {
        charges = initialShippingCharges * quotient;
      } else {
        quotient = quotient + 1;
        charges = initialShippingCharges * quotient;
      }
    }
    console.log("charges: ", charges);
    return charges;
  };

  const handleUpdateSubtotalPrice = (e) => {
    if (qty < minimumQty || qty < 0) {
      toast.error(`Minimum quantity should be ${minimumQty}`);
      setQty(minimumQty);
      let charges = calCharges(minimumQty);
      dispatch({
        type: "UPDATE_CHECKOUT_QUANTITY",
        id: id,
        quantity: minimumQty,
      });
      dispatch({
        type: "UPDATE_SHIPPING",
        id: id,
        item: {
          shippingCharges: parseInt(charges),
        },
      });
      dispatch({
        type: "UPDATE_ADMIN_COMMISSION",
        id: id,
        quantity: minimumQty,
      });
    } else if (qty > maximumQuantity) {
      setQty(maximumQuantity);
      let charges = calCharges(maximumQuantity);
      toast.error(`Maximum quantity should be ${maximumQuantity}`);
      dispatch({
        type: "UPDATE_CHECKOUT_QUANTITY",
        id: id,
        quantity: maximumQuantity,
      });
      dispatch({
        type: "UPDATE_SHIPPING",
        id: id,
        item: {
          shippingCharges: parseInt(charges),
        },
      });
      dispatch({
        type: "UPDATE_ADMIN_COMMISSION",
        id: id,
        quantity: maximumQuantity,
      });
    } else {
      let charges = calCharges(e.target.value);
      dispatch({
        type: "UPDATE_CHECKOUT_QUANTITY",
        id: id,
        quantity: e.target.value,
      });

      dispatch({
        type: "UPDATE_SHIPPING",
        id: id,
        item: {
          shippingCharges: parseInt(charges),
        },
      });
      dispatch({
        type: "UPDATE_ADMIN_COMMISSION",
        id: id,
        quantity: e.target.value,
      });
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="checkoutProduct">
        <img
          className="checkoutProduct__image col-lg-3 col-md-4 col-sm-12"
          src={image}
          alt=""
        />

        <div className="checkoutProduct__info col-lg-9 col-md-8 col-sm-12">
          <p className="checkoutProduct__title">
            <Link to={`/product-detail/${id}`}>{title}</Link>
          </p>
          <div className="price_quantity_div">
            <p style={{ margin: "3px 0px" }}>
              <small>PKR</small>&nbsp;<strong>{price}</strong>
            </p>
            <div className="price_quantity">
              {isMultipleAllow ? (
                <Form.Control
                  type="number"
                  as="select"
                  placeholder="Quantity"
                  value={qty}
                  onChange={handleQuantityUpdate}
                  onBlur={handleUpdateSubtotalPrice}
                  style={{ height: "30px", width: "75px", fontSize: "14px" }}
                >
                  {chooseMultiple > 1 ? <option>{minimumQty}</option> : ""}
                  {maximumQuantity >= chooseMultiple ? (
                    minimumQuantity * chooseMultiple <= maximumQuantity ? (
                      <option>{minimumQty * chooseMultiple}</option>
                    ) : (
                      // <option value={""} disabled></option>
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {array?.map((arr) => (
                    <option key={arr}>{arr}</option>
                  ))}
                </Form.Control>
              ) : (
                <Form.Control
                  type="number"
                  placeholder="Quantity"
                  value={qty}
                  onChange={handleQuantityUpdate}
                  onBlur={handleUpdateSubtotalPrice}
                  style={{ height: "30px", width: "75px", fontSize: "14px" }}
                />
              )}

              <span style={{ color: "lightgray", padding: "0px 10px" }}>
                {" "}
                |{" "}
              </span>
              {!hideButton && (
                <a
                  style={{ cursor: "pointer", fontSize: "13px" }}
                  onClick={removeFromBasket}
                >
                  Delete
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutProduct;
