import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import {
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Button,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
// import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { GoLocation } from "react-icons/go";
import { axiosClient } from "./helper";
import { useStateValue } from "./StateProvider";
import Navbar from "./components/Navbar/Navbar";
import "./components/Navbar/Navbar.css";
import deosaiLogo from "./assets/desosaiLogo.png";
import SpadaSoftLogo from "./assets/logos/st.png";
import { BsInfoCircle } from "react-icons/bs";

function Header(props) {
  const [searchProduct, setSearchProduct] = useState("");
  const [searchCategory, setSearchCategory] = useState("all");
  const [someCategories, setSomeCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [display, setDisplay] = useState(false);
  const wrapperRef = useRef(null);
  const [
    { basket, choosedLocation, selectedCityCode, selectedCityRegion },
    dispatch,
  ] = useStateValue();
  // const [location, setLocation] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCity, setSelectedCity] = useState("All");
  const [allCities, setAllCities] = useState([]);
  const [show, setShow] = useState(false);

  let history = useHistory();

  useEffect(() => {
    getAllCities();
    getCategory();
    getTags();
    getSortedCategories();
    // getCurrentLocation();
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const getSortedCategories = () => {
    axiosClient()
      .get("/getSortedCategories")
      .then((response) => {
        setSomeCategories(response?.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const getAllCities = () => {
    axiosClient()
      .get("/getAllCities")
      .then((response) => {
        setAllCities(response.data?.data);
        getCurrentLocation(response.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const getCurrentLocation = (cities) => {
    axios
      .get("https://geolocation-db.com/json/geoip.php?json=callback")
      .then((response) => {
        let shortCode;
        if (response.data.city) {
          shortCode = cities?.filter(
            (x) =>
              x?.cityName?.toLowerCase() === response.data.city?.toLowerCase()
          );
        }

        dispatch({
          type: "CHANGE_LOCATION",
          item: {
            choosedLocation: response.data.city
              ? response.data.city
              : "Islamabad",
            selectedCityCode: shortCode[0]?.cityCode
              ? shortCode[0]?.cityCode
              : "ISB",
            selectedCityRegion: shortCode[0]?.provinceId.provinceName
              ? shortCode[0]?.provinceId.provinceName
              : "Islamabad",
          },
        });
      });
  };

  const getCategory = () => {
    axiosClient()
      .get("/getCategories")
      .then((response) => {
        if (response.status === 200) {
          setCategory(response.data.categories);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getTags = () => {
    const pokemon = [];
    axiosClient()
      .get("/getAllTagNames")
      .then((response) => {
        if (response.status === 200) {
          response.data.tags &&
            response.data.tags.map((value) =>
              pokemon.push(value.name.toLowerCase())
            );
          setTags(pokemon);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const login = () => {
    history.push("/login");
  };

  const logout = () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleSearchOnKey = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (!searchProduct) return "";
    history.push(`/searchresults/${searchCategory}/${searchProduct}`);
  };
  const searchOnSelectTag = (tag) => {
    if (!tag) return "";
    history.push(`/searchresults/${searchCategory}/${tag}`);
  };

  const onChangeSearch = (tag) => {
    setSearchProduct(tag);
    setDisplay(false);
    searchOnSelectTag(tag);
  };

  const handleSearchCategory = (e) => {
    setSearchCategory(e.target.value);
  };

  const onChangeCity = (e) => {
    // let choosedCity =
    //   e.target.value === "All" ? "All" : JSON.parse(e.target.value);
    if (e.target.value) {
      let choosedCity = JSON.parse(e.target.value);
      setSelectedCity(e.target.value.cityName);
      dispatch({
        type: "CHANGE_LOCATION",
        item: {
          choosedLocation: choosedCity.cityName,
          selectedCityCode: choosedCity.cityCode,
          selectedCityRegion: choosedCity.provinceId,
        },
      });
      dispatch({
        type: "EMPTY_BASKET",
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <nav className="header_desktop_nav">
        {/* <div className="become_vendor">
          <a target="_blank" href={"https://deosai-seller.herokuapp.com/"}>
            Become a vendor
          </a>
        </div> */}
        <div className="header__top">
          <Link to="/">
            <img
              style={{ width: "120px", height: "auto" }}
              src={SpadaSoftLogo}
              alt="iown.pk"
            />
          </Link>
          {choosedLocation ? (
            <div className="header__address" onClick={handleShow}>
              <div className="header__address-icon">
                <GoLocation />
              </div>
              <div className="header__option" style={{ marginLeft: "2px" }}>
                <span
                  className="header__optionLineOne"
                  style={{ marginBottom: "-2px" }}
                >
                  Deliver to
                </span>
                <span className="header__optionLineTwo text-capitalize">
                  {choosedLocation === "All" ? "Pakistan" : choosedLocation}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="header__search">
            <InputGroup
              style={{ flexWrap: "nowrap" }}
              // ref={wrapperRef}
            >
              <select
                variant="outline-secondary"
                title="All"
                id="input-group-dropdown-1"
                onChange={handleSearchCategory}
                style={{ width: "auto" }}
              >
                <option value="all">All</option>
                {category?.map((cat) => (
                  <option
                    key={cat._id}
                    value={cat._id}
                    className="text-capitalize"
                  >
                    {cat.name}
                  </option>
                ))}
              </select>
              <InputGroup.Append>
                <FormControl
                  onClick={() => setDisplay(!display)}
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                  onKeyPress={handleSearchOnKey}
                />
                {display && (
                  <div
                    className="autoContainer"
                    // ref={wrapperRef}
                  >
                    {tags
                      ?.filter(
                        (name) => name.indexOf(searchProduct.toLowerCase()) > -1
                      )
                      .map((value, i) => {
                        return (
                          <div
                            onClick={() => onChangeSearch(value)}
                            className="option"
                            key={i}
                            tabIndex="0"
                          >
                            <span>{value}</span>
                          </div>
                        );
                      })}
                  </div>
                )}
                <SearchIcon
                  className="header__searchIcon"
                  onClick={handleSearch}
                />
              </InputGroup.Append>
            </InputGroup>
          </div>

          <div className="header__containerTwo">
            <div className="header__nav">
              <div
                // className="header__option"
                style={{ cursor: "pointer" }}
              >
                {localStorage.getItem("token") ? (
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={localStorage.getItem("userName")}
                    className="user_dropdown "
                  >
                    <Dropdown.Item onSelect={logout}>Sign Out</Dropdown.Item>
                    <Dropdown.Item
                      onSelect={() => history.push("/manage-profile")}
                    >
                      Account
                    </Dropdown.Item>
                  </DropdownButton>
                ) : (
                  <Button
                    style={{ backgroundColor: "transparent", color: "#fff" }}
                    onClick={login}
                  >
                    Sign In
                  </Button>
                )}
              </div>
              {/* <Link> */}
              <a
                href="https://wa.me/923454007766?text=Hi, can you check the availability of product?"
                target="_blank"
              >
                {/* <div className="header__option">
                  <span className="header__optionLineOne">Contact us</span>
                  <span className="header__optionLineTwo">0345 4007766</span>
                </div> */}
              </a>
              {/* </Link> */}
              <Link to="/checkout">
                <div className="header__optionBasket">
                  <ShoppingCartIcon />
                  <span className="header__optionLineTwo header__basketCount ml-1">
                    {basket?.length}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="header__bottom">
          <div className="header__bottom-nav">
            <Navbar />
            {someCategories?.map((cat) => (
              <span
                className="text-capitalize"
                key={cat._id}
                onClick={() =>
                  history.push(
                    `/shop/${cat._id}/${pageNumber}/${choosedLocation}`
                  )
                }
              >
                {cat.name}
              </span>
            ))}
            {/* <span>
              <Link to="/iwant" className="header__link">
                iWant
              </Link>
            </span> */}
          </div>
        </div>
      </nav>
      <nav className="header_mobile_nav">
        {/* <div
          className="become_vendor"
          style={{ justifyContent: "center", paddingRight: "0px" }}
        >
          <a target="_blank" href={"https://deosai-seller.herokuapp.com/"}>
            Become a vendor
          </a>
        </div> */}
        <div className="header__top2">
          <div className="row1">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Navbar />
              <Link to="/">
                <img
                  style={{ width: "110px", height: "80px", paddingTop: "9px" }}
                  src={SpadaSoftLogo}
                  alt="iown.pk"
                />
              </Link>
            </div>
            <div className="header__option2" style={{ cursor: "pointer" }}>
              {localStorage.getItem("token") ? (
                <DropdownButton
                  id="dropdown-basic-button"
                  title={localStorage.getItem("userName")}
                  style={{ background: "transparent", border: "none" }}
                >
                  <Dropdown.Item onSelect={logout}>Sign Out</Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => history.push("/manage-profile")}
                  >
                    Account
                  </Dropdown.Item>
                </DropdownButton>
              ) : (
                <Button
                  style={{
                    backgroundColor: "transparent",
                    marginRight: "10px",
                  }}
                  onClick={login}
                >
                  Sign In
                </Button>
              )}
              <Link to="/checkout">
                <div className="header__optionBasket">
                  <ShoppingCartIcon />
                  <span className="header__optionLineTwo header__basketCount ml-1">
                    {basket?.length}
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="header__search"
            style={{
              marginTop: "1%",
              marginBottom: "2%",
            }}
          >
            <InputGroup style={{ flexWrap: "nowrap" }}>
              <select
                variant="outline-secondary"
                title="All"
                id="input-group-dropdown-1"
                onChange={handleSearchCategory}
              >
                <option value="all">All</option>
                {category?.map((cat) => (
                  <option
                    key={cat._id}
                    value={cat._id}
                    className="text-capitalize"
                  >
                    {cat.name}
                  </option>
                ))}
              </select>
              <InputGroup.Append>
                <FormControl
                  onClick={() => setDisplay(!display)}
                  value={searchProduct}
                  onChange={(e) => setSearchProduct(e.target.value)}
                  onKeyPress={handleSearchOnKey}
                />
                {display && (
                  <div
                    className="autoContainer"
                    // ref={wrapperRef}
                  >
                    {tags
                      ?.filter(
                        (name) => name.indexOf(searchProduct.toLowerCase()) > -1
                      )
                      .map((value, i) => {
                        return (
                          <div
                            onClick={() => onChangeSearch(value)}
                            className="option"
                            key={i}
                            tabIndex="0"
                          >
                            <span>{value}</span>
                          </div>
                        );
                      })}
                  </div>
                )}
                <SearchIcon
                  className="header__searchIcon"
                  onClick={handleSearch}
                />
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <div
          className="header__bottom"
          style={{
            padding: "8px 6px",
            minWidth: "440px",
            overflowX: "scroll",
          }}
        >
          <div className="header__bottom-nav">
            {someCategories?.slice(0, 4).map((cat) => (
              <span
                className="text-capitalize"
                key={cat._id}
                onClick={() =>
                  history.push(
                    `/shop/${cat._id}/${pageNumber}/${choosedLocation}`
                  )
                }
                // onClick={() => (window.location = `/shop/${cat._id}`)}
              >
                {cat.name}
              </span>
            ))}
            <span>
              <Link to="/iwant" className="header__link">
                iWant
              </Link>
            </span>
          </div>
        </div>
      </nav>

      <Modal show={show} onHide={handleClose} className="city-popup">
        <Modal.Header closeButton>
          <Modal.Title>Choose your location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label
              column
              sm={12}
              style={{ color: "#5a5858", fontSize: "14px" }}
            >
              Select Location
            </Form.Label>
            <Col sm={12} md={12} lg={12}>
              <Form.Control
                as="select"
                value={selectedCity}
                onChange={onChangeCity}
                style={{ boxShadow: "0px 0px 10px #cfcfcf" }}
              >
                {/* <option value="All">Pakistan</option> */}
                <option value="">-- Choose a location --</option>
                {allCities?.map((city) => (
                  <option
                    key={city._id}
                    value={JSON.stringify(city)}
                    className="text-capitalize"
                  >
                    {city.cityName}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          <div className="modal-info">
            <BsInfoCircle style={{ color: "green" }} className="mt-1 mr-1" />{" "}
            <div>
              Please note that all products from your cart will be removed on
              changing location
            </div>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
