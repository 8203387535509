import axios from "axios";

// export const BASE_URL = "https://deosai-backend-2.herokuapp.com/";

export const BASE_URL = "https://spadatradersbackend.herokuapp.com/";
// export const BASE_URL = "https://deosai-backend.herokuapp.com/";
// export const BASE_URL = "http://be31-182-185-209-237.ngrok.io";
// export const BASE_URL = "http://192.168.0.73:2000/";

export function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    config.headers.common = {
      "x-auth-token": `${localStorage.getItem("token")}`,
    };
    return config;
  });

  // instance.defaults.headers.common[
  //   "Authorization"
  // ] = store.getState().mainStore.accessToken;

  return instance;
}

export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export var formatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "PKR",
  maximumFractionDigits: 0,
});
