import React from "react";
import { Link } from "react-router-dom";
import "./Category.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
});
export default function Category(props) {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {/* <Link to="/shop"> */}
      <Card className={classes.root}>
        <CardActionArea className="card-div">
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className="text-capitalize"
            >
              {props.heading}
            </Typography>
            <Link to={props.link}>
              <CardMedia
                className="card-media"
                image={props.src}
                // title="Shop by Category"
              />
            </Link>
          </CardContent>
        </CardActionArea>
        <CardActions className="card-buttons">
          <Link to={props.link}>
            <Button style={{ color: "#3eb986", fontWeight: "bold" }}>
              See More
            </Button>
          </Link>
        </CardActions>
      </Card>
      {/* </Link> */}
    </Grid>
  );
}
