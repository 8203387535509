import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./DashboardSidenav.css";
const buyerId = localStorage.getItem("userId");
class DashboardSidenav extends Component {
  render() {
    return (
      <div className="dash_sidenav">
        <h3>My Account</h3>
        <ul>
          <Link to="/manage-profile">
            <li>Manage Profile</li>
          </Link>
        </ul>
        <h3>Orders</h3>
        <ul>
          <Link to="/myorders">
            <li>My Orders</li>
          </Link>
          <Link to={`/my-invoices/${buyerId}`}>
            <li>My Invoices</li>
          </Link>
        </ul>

        <h3>Requests</h3>
        <ul>
          <Link to="/return-requests">
            <li>Return Requests</li>
          </Link>
          <Link to="/iwant-requests">
            <li>iWant Requests</li>
          </Link>
        </ul>

        <Link to="/wishlist">
          <h3 className="wish_link">Product Wishlist</h3>
        </Link>
      </div>
    );
  }
}
export default DashboardSidenav;
