import React from "react";
import Blogs from "../../components/blogs/index";
import { Grid, Container, Typography } from "@material-ui/core";
import Header from "../../../src/Header";
import Footer from "../../../src/components/Footer/Footer";
import { Loader } from "react-bootstrap-typeahead";
import { Carousel } from "react-bootstrap";

import { blogsData } from "../../data/data";

export default function blogs() {
  return (
    <>
      <Header />

      <Container className="container-max-width">
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Carousel>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid"></Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner2}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-2"></Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner3}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-3"></Grid>
            </Carousel.Item>
          </Carousel>
        </div>

        <Grid container>
          {blogsData.map(() => (
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <Blogs />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
