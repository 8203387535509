import React, { useState } from "react";
import "./SearchResults.css";
import { withRouter, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
// import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
// import ShopFilterSection from "../../components/ShopFilterSection/ShopFilterSection";
// import SearchProductCard from "../../components/searchProductCard/SearchProductCard";
import ShopProductCard from "../../components/ShopProductCard/ShopProductCard";
import SearchNotFound from "../searchNotFound/searchNotFound";
import { axiosClient } from "../../helper";

const SearchResults = (props) => {
  // const [searchedItem, setSearchedItem] = useState("Laptops");
  // const [category, setCategory] = useState([]);
  const [results, setResults] = useState([]);
  const [loader, setLoader] = useState(true);

  const { categoryId, productName } = useParams();

  React.useEffect(() => {
    axiosClient()
      .get(`/search/${categoryId}/${productName}`)
      .then((res) => {
        setLoader(false);
        setResults(res.data.products);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });

    // let q = categoryId;
    // getCategory();
  }, [categoryId, productName]);

  // const getCategory = () => {
  //   axiosClient()
  //     .get("/getCategories")
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setCategory(response.data.categories);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const calculatePrice = (isPercentage, isFixed, price, commissionAmount) => {
    if (isPercentage) {
      return (price / 100) * commissionAmount + price;
    } else if (isFixed) {
      return price + commissionAmount;
    } else {
      return price;
    }
  };
  return (
    <>
      {/* <Container>
        <Row>
          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}> */}
      {/* <div
              className="result-info-bar"
              style={{ marginTop: "0px", marginBottom: "0px", height: "28px" }}
            > */}
      {/* <p>
                1-24 of over 100,000 results for{" "}
                <span style={{ color: "#c45500" }}>{searchedItem}</span>
              </p> */}

      {/* <div>
                <form>
                  <label>Sort by</label>
                  <select id="sort" name="sort">
                    <option value="Featured">Featured</option>
                    <option value="Price: Low to High">
                      Price: Low to High
                    </option>
                    <option value="Price: High to Low">
                      Price: High to Low
                    </option>
                    <option value="Avg. Customer Review">
                      Avg. Customer Review
                    </option>
                    <option value="Newest Arrivals">Newest Arrivals</option>
                  </select>
                </form>
              </div> */}
      {/* </div> */}
      {/* </Col>
        </Row>
      </Container> */}
      <div
        className="container"
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <h1 className="search-heading">Search Results</h1>
        {/* <Grid
          item
          lg={2}
          md={12}
          sm={12}
          xs={12}
          className="search_filter_column"
        >
          <ShopFilterSection
            subCategory={category}
            getSubcategory={getCategory}
          />
        </Grid> */}
        {results ? (
          <Row
          // item
          // lg={12}
          // md={12}
          // sm={12}
          // xs={12}
          // style={{ marginLeft: "24px" }}
          >
            {loader ? (
              <div className="loaders">
                <CircularProgress />
              </div>
            ) : (
              results?.map((search) => (
                <>
                  {/* <div className="search_card_desktop">
                    <SearchProductCard
                      id={search._id}
                      src={search.image[0]}
                      alt={search.name}
                      link={`/product-detail/${search._id}`}
                      title={search.name}
                      brand={search.brand}
                      price={calculatePrice(
                        search.userID.isPercentage,
                        search.userID.isFixed,
                        search.price,
                        search.userID.commissionAmount
                      )}
                      userID={search.userID}
                      ratingValue={search.averageRating}
                      NoOfReviews={search.averageRating}
                    />
                  </div> */}

                  <Col lg={3} md={6} sm={12}>
                    <ShopProductCard
                      id={search._id}
                      src={search.image[0]}
                      alt={search.name}
                      link={`/product-detail/${search._id}`}
                      title={search.name}
                      brand={search.brand}
                      price={calculatePrice(
                        search.isPercentage,
                        search.isFixed,
                        search.price,
                        parseInt(search.commissionAmount)
                      )}
                      userID={search.userID}
                      ratingValue={search.averageRating}
                      NoOfReviews={search.averageRating}
                      className="search_card_mobile"
                    />
                  </Col>
                </>
              ))
            )}
          </Row>
        ) : (
          <SearchNotFound />
        )}
      </div>
    </>
  );
};
export default withRouter(SearchResults);
