import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Header from "../../Header";
import Footer from "../../components/Footer/Footer";

import axios from "axios";
import SpadaLoader from "../../assets/loader/spadal.mp4";
import { Helmet } from "react-helmet";
import { useStateValue } from "../../StateProvider";
import { featureData } from "../../data/data";
import Typography from "@material-ui/core/Typography";
import FeatureProductCard from "../../components/FeaturedProductsCard/index";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "./Home.css";
import { axiosClient } from "../../helper";
import Category from "../../components/Category/Category";
// import banner from "../../assets/banners/Sample.jpeg";
import banner2 from "../../assets/banners/Banner1.jpeg";
import banner3 from "../../assets/banners/Banner2.jpg";
import SpadaTradersBanner from "../../assets/banners/spada-trader-banner.jpg";
import SpadaTradersBanner2 from "../../assets/banners/spada-trader-banner-2.jpg";
import SpadaTradersBanner3 from "../../assets/banners/spada-trader-banner-3.jpg";

var settings = {
  dots: false,
  infinite: true,
  // centerMode: true,
  // speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  // nextArrow: <ChevronRightIcon />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};

// const settings1 = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
// };
var settings1 = {
  // dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Home(props) {
  const [{ choosedLocation }] = useStateValue();
  const [category, setCategory] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [spadaLoader, setSpadaLoader] = useState(false);
  useEffect(() => {
    // setLoader(true);
    setSpadaLoader(true);
    axios
      .get(`https://spadatradersbackend.herokuapp.com/getTopSellingProducts/`)
      .then((response) => {
        setSpadaLoader(false);

        console.log("response.data", response);
        setProductsData(response.data.products);
        // setTotalBlogs(response.data.data.blogCount);
        // setLoader(false);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    getCategory();
    getProducts();
  }, [choosedLocation]);

  const getProducts = () => {
    axiosClient()
      .get(`/getFeaturedProducts/${choosedLocation}`)
      .then((res) => {
        setFeaturedProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosClient()
      .get("/getTopSellingProducts")
      .then((res) => {
        setTopSellingProducts(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategory = () => {
    axiosClient()
      .get("/getCategories")
      .then((response) => {
        if (response.status === 200) {
          setCategory(response.data.categories);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  return (
    <>
      <Helmet>
        <title>Spada Traders</title>
        <meta name="Spada Traders" content="Spada Traders Home Page" />
      </Helmet>

      <Header />

      <Container className="container-max-width">
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Carousel>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid"></Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner2}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-2">
                <Grid className="banner-text-main-div">
                  <Typography className="banner-text-1">
                    refresh your space
                    <Typography className="banner-text-2">
                      shop the home store
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
          className="d-block w-100"
          src={SpadaTradersBanner3}
          alt="iBuild"
        /> */}
              <Grid className="home-banner-main-grid-3"></Grid>
            </Carousel.Item>
          </Carousel>
        </div>

        <Grid container justify="center">
          {category.slice(0, 8).map((cat) => (
            <Category
              key={cat._id}
              heading={cat.name}
              src={cat.image}
              link={`/shop/${cat._id}/1/${choosedLocation}`}
            />
          ))}
        </Grid>

        {/* <div className="slider-div2">
    <div className="slider-heading-div">
      <h3 className="slider-heading">Deosai Top Sellers</h3>
    </div>
    <Slider {...settings} arrows={true}>
      {topSellingProducts
        .slice(0, 15)
        .sort(() => Math.random() - 0.5)
        .map((featureProduct) => (
          <div className="slider-div">
            <Link
              to={{ pathname: `/product-detail/${featureProduct._id}` }}
            >
              <img
                src={featureProduct.image[0]}
                alt="product"
                className="slider-image"
              />
            </Link>
          </div>
        ))}
    </Slider>
  </div> */}

        {/* {featuredProducts && (
          <div className="slider-div2">
            <div className="slider-heading-div">
              <h3 className="slider-heading">Featured Products</h3>
            </div>
            <Slider {...settings} arrows={true}>
              {featuredProducts?.map((featureProduct) => (
                <div className="slider-div" key={featureProduct._id}>
                  <Link
                    to={{ pathname: `/product-detail/${featureProduct._id}` }}
                  >
                    <img
                      src={featureProduct.image[0]}
                      alt="product"
                      className="slider-image"
                    />
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
   )} */}

        <Typography className="featured-products-text">
          Featured Products
        </Typography>

        {spadaLoader ? (
          <Grid container style={{ justifyContent: "center" }}>
            <Grid item>
              <img src={SpadaLoader} className="spadaloader-image" />
            </Grid>
          </Grid>
        ) : (
          <Link
            to={{
              pathname: `/product-detail/${productsData?._id}`,
            }}
          >
            <Slider {...settings1}>
              {productsData?.map((data) => (
                <FeatureProductCard data={data} />
              ))}
            </Slider>
          </Link>
        )}

        {/* 
// <Grid container justify="center" style={{ marginTop: "1.5%" }}>
//   <Grid item xs={12} sm={6} md={3} lg={3} className="shop-card-home">
//     <Card style={{ marginLeft: 10, marginRight: 10 }}>
//       <CardActionArea>
//         <CardContent className="inner-shop-card-home">
//           <Typography
//             gutterBottom
//             variant="h5"
//             component="h1"
//             style={{ marginLeft: "2%" }}
//           >
//             Comfy styles for her
//           </Typography>
//           <Grid container justify="center">
//             {ComfyDress.map((comfydress) => (
//               <ShopCategory
//                 src={comfydress.src}
//                 heading={comfydress.title}
//               />
//             ))}
//           </Grid>
//         </CardContent>
//         <CardActions className="category-buttons">
//           <Link to="/products">
//             <Button size="small" color="primary">
//               Shop Now
//             </Button>
//           </Link>
//         </CardActions>
//       </CardActionArea>
//     </Card>
//   </Grid>

//   <Category
//     heading="Shop Laptops & Tablets"
//     src="https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/May/Dashboard/Fuji_Dash_Laptops_379x304_1X_en_US._SY304_CB418608471_.jpg"
//     link="/shop"
//   />
//   <Category
//     heading="Explore Home Bedding"
//     src="https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/May/Dashboard/Fuji_Dash_HomeBedding_Single_Cat_1x._SY304_CB418596953_.jpg"
//     link="/shop"
//   />
//   <Category
//     heading="Create with strip lights"
//     src="https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/May/Dashboard/Fuji_Dash_StripLighting_379x304_1X_en_US._SY304_CB418597476_.jpg"
//     link="/shop"
//   />
// </Grid> */}
      </Container>

      <Footer />
    </>
  );
}

export default Home;
