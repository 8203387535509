import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { Container, Row, Col } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import Button from "@material-ui/core/Button";

import "./searchNotFound.css";

import { axiosClient } from "../../helper";

var settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
  ],
};
class searchNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isopen: false,
      TopProducts: [],
      category: [],
    };
  }
  componentDidMount() {
    this.getCategory();
  }
  getCategory = () => {
    axiosClient()
      .get("/getFeaturedProducts")
      .then((res) => {
        this.setState({ TopProducts: res.data.products });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <>
        <Grid container>
          {/* <Row> */}
          <Grid item lg={2} md={3} sm={3} xs={12}>
            <div className="bg-img"></div>
          </Grid>
          <Grid item lg={10} md={9} sm={9} xs={12}>
            <div style={{ marginLeft: "4%", marginBottom: "4%" }}>
              <div className="no-results-div">
                <span className="no-results">No results found</span>
                <div>
                  <span className="try-checking">
                    Can't find what you are looking for?
                    <br /> Click the button below & tell us about your desired
                    product.
                    <br /> We'll make sure you get it next time
                  </span>
                  <br />
                  <Link to="/iwant">
                    <Button
                      variant="contained"
                      backgroundColor="#f4ce72"
                      color="primary"
                    >
                      I want !
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="need-help-div">
                <h2 className="need-help">Need help?</h2>
                <div className="visit-section">
                  <Link to="#">Visit the help section</Link>
                  {""} <span>or</span> <Link to="#">contact us</Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                marginTop: "1.5%",
                marginBottom: "2%",
              }}
              className="slider-div2"
            >
              <div className="slider-heading-div">
                <h3 className="slider-heading" style={{ marginBottom: "2%" }}>
                  Top Products
                </h3>
                <Link className="slider-shopnow-link">Shop Now</Link>
              </div>
              <Slider {...settings} arrows={true}>
                {this.state.TopProducts.map((topproduct) => (
                  <div className="slider-div">
                    <Link
                      to={{ pathname: `/product-detail/${topproduct._id}` }}
                    >
                      <img
                        src={topproduct.image[0]}
                        alt="product"
                        className="slider-image"
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </>
      // </Container>
    );
  }
}
export default searchNotFound;
