import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

function IwantCollapseRow(props) {
  const { iwantRequest } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow className="main_rows">
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{iwantRequest.productName}</TableCell>
        <TableCell>{iwantRequest.location}</TableCell>
        <TableCell>{iwantRequest.city}</TableCell>
        <TableCell>{iwantRequest.status}</TableCell>
        <TableCell>
          <span>{new Date(iwantRequest.created_at)?.toDateString()}</span>
          <br />
          <span className="mt-1">
            {" "}
            {new Date(iwantRequest.created_at)?.toLocaleTimeString()}
          </span>
        </TableCell>
      </TableRow>
      <TableRow style={{ backgroundColor: "#fff" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <h6 style={{ fontWeight: "600" }}>Details</h6>
              <Table
                size="small"
                aria-label="purchases"
                className="collapse-rows"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Product Description</TableCell>
                    <TableCell>Admin Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={iwantRequest._id}>
                    <TableCell className="pt-2 w-50">
                      {iwantRequest.description}
                    </TableCell>
                    <TableCell className="pt-2 w-50">
                      {iwantRequest.adminComment ? (
                        <span>{iwantRequest.adminComment}</span>
                      ) : (
                        <span>----</span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default IwantCollapseRow;
